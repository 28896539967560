import Axios from "axios";
import { appConfig } from "../constants/config";
var qs = require('qs')

var icbSector = {
    async getItems(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.icbSector + "?" + queryString);
    },
    async getItem(payload) {
        return await Axios.get(appConfig.api.icbSector + "/" + payload.id);
    },
    async create(payload) {
        return await Axios.post(appConfig.api.icbSector, payload);
    },
    async update(payload) {
        return await Axios.put(appConfig.api.icbSector + "/" + payload.id, payload);
    },
    async delete(payload) {
        return await Axios.delete(appConfig.api.icbSector + "/" + payload.id);
    }
}

export default icbSector;
