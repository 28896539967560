import Vue from 'vue'
import queueService from '../../services/queue'

const state = {
    clientInterval: null,
    sp_update_meta: [],
}

const getters = {
    getSpUpdateMeta(state) {
        return state.sp_update_meta
    }
}

const mutations = {
    START_CHECK_SP_UPDATE_META(state, {
        clientId,
        batchId
    }) {
        // Start checking queue... (BatchID)
        state.clientInterval = setInterval(() => {
            queueService.getJobBatch({
                name: 'sp_update_meta',
                clientId: clientId,
                batchId: batchId
            }).then(resp => {
                state.sp_update_meta = state.sp_update_meta.filter(x => x.clientId !== resp.data.clientId)
                state.sp_update_meta.push(resp.data)
                if (parseInt(resp.data.result) !== 1  || resp.data.data.finishedAt) {
                    // Stop checking queue!
                    state.sp_update_meta = state.sp_update_meta.filter(x => x.clientId !== resp.data.clientId)
                    clearInterval(state.clientInterval);
                    state.clientInterval = null;
                }
            }).catch(err => {
                state.sp_update_meta = state.sp_update_meta.filter(x => x.clientId !== resp.data.clientId)
                clearInterval(state.clientInterval);
                state.clientInterval = null;
            })
        }, 2000);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}