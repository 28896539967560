import Axios from "axios";
import {
  appConfig
} from "../constants/config";
var qs = require('qs')

var sharepoint = {
  async checkClientSite(payload) {
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/check-client-site");
  },
  async getDocumentsByType(payload, config=null) {
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/get-documents-by-type?document_type=" + payload.document_type, config);
  },
  async getAvailableDrives(payload) {
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/available-drives");
  },
  async getAvailableDrivesBySite(payload) {
    return await Axios.get(appConfig.api.sharepoint + "/site/" + payload.id + "/available-drives");
  },
  async listFolders(payload) {
    payload.path_to_root = payload.path_to_root ? payload.path_to_root.split('/').filter(x => x).map(y => encodeURIComponent(y)).join('/') : '';
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/list-folders?drive_id=" + payload.drive_id + "&path_to_root=" + payload.path_to_root);
  },
  async listAcclimeSign(payload) {
    payload.path_to_root = payload.path_to_root ? payload.path_to_root.split('/').filter(x => x).map(y => encodeURIComponent(y)).join('/') : '';
    if (payload.use_my_drive) {
      return await Axios.get(appConfig.api.sharepoint + "/acclime-sign-my-drive/list-folders?drive_id=" + payload.drive_id + "&path_to_root=" + payload.path_to_root + "&include_files=" + payload.include_files + "&use_my_drive=" + payload.use_my_drive);
    } else {
      return await Axios.get(appConfig.api.sharepoint + "/acclime-sign/" + payload.siteId + "/list-folders?drive_id=" + payload.drive_id + "&path_to_root=" + payload.path_to_root + "&include_files=" + payload.include_files);
    }
  },
  async listFoldersBySite(payload) {
    payload.path_to_root = payload.path_to_root ? payload.path_to_root.split('/').filter(x => x).map(y => encodeURIComponent(y)).join('/') : '';
    return await Axios.get(appConfig.api.sharepoint + "/site/" + payload.siteId + "/list-folders?drive_id=" + payload.drive_id + "&path_to_root=" + payload.path_to_root + "&include_files=" + payload.include_files + "&use_my_drive=" + payload.use_my_drive);
  },
  async listBd(payload) {
    return await Axios.get(appConfig.api.sharepoint + "/list-bd");
  },
  async downloads(payload) {
    return await Axios.post(appConfig.api.sharepoint + "/downloads", payload, {
      responseType: 'blob'
    });
  },
  async getPdfPublicUrl(payload) {
    return await Axios.post(appConfig.api.sharepoint + "/get-public-url", payload);
  },
  async getSites() {
    return await Axios.get(appConfig.api.sharepoint + "/sites");
  },
  async createFolder(payload) {
    payload.path_to_root = payload.path_to_root ? payload.path_to_root.split('/').filter(x => x).map(y => encodeURIComponent(y)).join('/') : '';
    payload.folderRelativeUrl = payload.drive_name + (payload.path_to_root && '/' + payload.path_to_root) + '/' + payload.new_folder_name;
    // console.log('payload: ', payload)
    return await Axios.post(appConfig.api.sharepoint + "/site/" + payload.siteId + "/create-folder", payload);
  },
  async listPayrollFolders(payload) {
    payload.path_to_root = payload.path_to_root ? payload.path_to_root.split('/').filter(x => x).map(y => encodeURIComponent(y)).join('/') : '';
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/list-folder-files?drive_id=" + payload.drive_id + "&path_to_root=" + payload.path_to_root + "&type=payroll-payslips");
  },
  async list(payload, config=null) {
    return await Axios.get(appConfig.api.sharepoint + "/" + payload.id + "/list?filters=" + JSON.stringify(payload.filters) + "&list_id=" + payload.list_id + "&drive_id=" + payload.drive_id, config);
  },
}

export default sharepoint;