import Vue from "vue"
import Axios from "axios"
import { appConfig, LSUriUser, LSUriClient } from "../constants/config"
import { getCurrentUser, getCurrentEmployee } from "@/utils"
import Cookies from 'js-cookie';

var auth = {
  async updateToken() {
    if (window.msal.isAuthenticated) {
      const msalToken = await window.msal.acquireToken()
      // console.log('TOKEN: ', token)
      const resp = await Axios.post(appConfig.api.updateToken, {
        token: msalToken.accessToken,
      })
      if (resp.data.user) {
        let user = getCurrentUser();
        user.mstoken = resp.data.user.mstoken

        localStorage.setItem("user", JSON.stringify(user))
        Cookies.set('access_token', JSON.stringify(resp.data.access_token));
      }
    }
  },
  async userLogin(user) {
    try {
      // Cookies.remove("user")
      localStorage.removeItem("user")
      localStorage.removeItem("permissions")
      Cookies.remove("access_token")
      localStorage.removeItem("session_index")
      localStorage.removeItem("name_id")
      localStorage.removeItem("last_visited_uris")
      localStorage.removeItem("fd")
      localStorage.removeItem(LSUriUser)
      localStorage.removeItem(LSUriClient)
      sessionStorage.removeItem(LSUriUser)
      sessionStorage.removeItem(LSUriClient)

      const resp = await Axios.post(appConfig.api.userLogin, user)
      if (resp.data.user) {
        resp.data.user.isUserPortal = true
        resp.data.user.isClientPortal = false
        resp.data.user.isExternalPortal = false

        localStorage.setItem("user", JSON.stringify(resp.data.user))
        Cookies.set('access_token', JSON.stringify(resp.data.access_token));
      }
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  },
  async clientLogin(user) {
    try {
      // Cookies.remove("user")
      // Cookies.remove("employee")
      // localStorage.removeItem("permissions")
      // Cookies.remove("access_token")
      // Cookies.remove("employee_access_token")

      localStorage.removeItem("user")
      localStorage.removeItem("employee")
      localStorage.removeItem("permissions")
      Cookies.remove("access_token")
      Cookies.remove("employee_access_token")
      localStorage.removeItem("session_index")
      localStorage.removeItem("name_id")
      localStorage.removeItem("last_visited_uris")
      localStorage.removeItem("fd")
      localStorage.removeItem(LSUriUser)
      localStorage.removeItem(LSUriClient)
      sessionStorage.removeItem(LSUriUser)
      sessionStorage.removeItem(LSUriClient)

      const resp = await Axios.post(appConfig.api.clientLogin, user)
      if (resp.data.user) {
        resp.data.user.isUserPortal = false
        resp.data.user.isClientPortal = true
        resp.data.user.isExternalPortal = false  

        localStorage.setItem("user", JSON.stringify(resp.data.user))
        Cookies.set('access_token', JSON.stringify(resp.data.access_token));

      }
      if (resp.data.employee) {
        localStorage.setItem("employee", JSON.stringify(resp.data.employee))
        Cookies.set('employee_access_token', JSON.stringify(resp.data.employee_access_token));
      }
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  },
  async externalLogin(user) {
    try {
      // Cookies.remove("user")
      // localStorage.removeItem("permissions")
      Cookies.remove("access_token")
      localStorage.removeItem("user")
      localStorage.removeItem("permissions")
      // localStorage.removeItem("access_token")
      localStorage.removeItem("session_index")
      localStorage.removeItem("name_id")
      localStorage.removeItem("last_visited_uris")
      localStorage.removeItem("fd")
      localStorage.removeItem(LSUriUser)
      localStorage.removeItem(LSUriClient)
      sessionStorage.removeItem(LSUriUser)
      sessionStorage.removeItem(LSUriClient)

      const resp = await Axios.post(appConfig.api.externalLogin, user)
      if (resp.data.access_token) {
        resp.data.user.isUserPortal = false
        resp.data.user.isClientPortal = false
        resp.data.user.isExternalPortal = true

        localStorage.setItem("user", JSON.stringify(resp.data.user))
        Cookies.set('access_token', JSON.stringify(resp.data.access_token));
      }
      return resp.data
    } catch (error) {
      throw error.response.data
    }
  },
  async logout() {
    try {
      const resp = await Axios.post(appConfig.api.logout)
      localStorage.removeItem("user")
      localStorage.removeItem("employee")
      localStorage.removeItem("permissions")
      Cookies.remove("access_token")
      Cookies.remove("employee_access_token")
      localStorage.removeItem("session_index")
      localStorage.removeItem("name_id")
      localStorage.removeItem("last_visited_uris")
      localStorage.removeItem("fd")
      localStorage.removeItem("redirectUrl")
      localStorage.removeItem("redirectUrlConsent")
      localStorage.removeItem(LSUriUser)
      localStorage.removeItem(LSUriClient)
      sessionStorage.removeItem(LSUriUser)
      sessionStorage.removeItem(LSUriClient)
      Vue.$cookies.remove("idleTime")
      return resp
    } catch (error) {
      localStorage.removeItem("user")
      localStorage.removeItem("employee")
      localStorage.removeItem("permissions")
      Cookies.remove("access_token")
      Cookies.remove("employee_access_token")
      localStorage.removeItem("session_index")
      localStorage.removeItem("name_id")
      localStorage.removeItem("last_visited_uris")
      localStorage.removeItem("fd")
      localStorage.removeItem("redirectUrl")
      localStorage.removeItem("redirectUrlConsent")
      localStorage.removeItem(LSUriUser)
      localStorage.removeItem(LSUriClient)
      sessionStorage.removeItem(LSUriUser)
      sessionStorage.removeItem(LSUriClient)
      Vue.$cookies.remove("idleTime")
      throw error
    }
  },
  getAuthHeader() {
    let access_token = Cookies.get("access_token")
    if (typeof access_token !== "undefined" && access_token !== null) {
      return "Bearer " + JSON.parse(access_token)
    }

    let employee_access_token = Cookies.get("employee_access_token")

    if (
      typeof employee_access_token !== "undefined" &&
      employee_access_token !== null
    ) {
      return "Bearer " + JSON.parse(employee_access_token)
    }

    return ""
  },
  async getContactProfile(payload) {
    const user = getCurrentUser();
    localStorage.removeItem('employee');
    Cookies.remove('employee_access_token');

    // if (!payload.isContact) {
    if (!user.isContact) {
      return await Axios.post(appConfig.employeeApi.profile, payload)
    } else {
      return await Axios.post(appConfig.clientApi.profile, payload)
    }
  },
  async getExternalProfile(payload) {
    return await Axios.get(appConfig.externalApi.profile + "/" + payload.email)
  },
  async checkExternalToken(payload) {
    return await Axios.post(appConfig.externalApi.checkExternalToken, payload)
  },
  // me
  async me() {
    const user = getCurrentUser()
    const AxiosMe = Axios.create()
    AxiosMe.interceptors.request.use(
      function(config) {
        config.headers.Authorization = auth.getAuthHeader()
        return config
      },
      function(err) {
        return Promise.reject(err)
      },
    )
    if (user && user.isUserPortal) {
      return await AxiosMe.get(appConfig.api.me)
    } else if (user && user.isClientPortal) {
      if (user.isContact) {
        return await AxiosMe.get(appConfig.clientApi.me)
      } else {
        return await AxiosMe.get(appConfig.employeeApi.me)
      }
    } else {
      return await AxiosMe.get(appConfig.externalApi.me)
    }
  },
}

export default auth