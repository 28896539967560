export const envMode = process.env.VUE_APP_MODE || "local"
export const defaultMenuType = "menu-default" // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = "/app"
export const clientRoot = "/client"
export const externalRoot = "/external"
export const searchPath = `${adminRoot}/#`

export const subHiddenBreakpoint = 768 //992 //1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = "en"
export const defaultDirection = "ltr"
export const localeOptions = [
  {
    id: "en",
    name: "English LTR",
    direction: "ltr",
  },
]

export const isAuthGuardActive = true
export const themeRadiusStorageKey = "theme_radius"
export const themeSelectedColorStorageKey = "theme_selected_color"
export const defaultColor = "light.acclime"
export const colors = [
  "acclime",
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
]

export const pageSizes = [10, 20, 30, 50, 100]
export const dataQualities = {
  high: "rgb(0, 38, 62)",
  medium: "rgb(92, 184, 178)",
  low: "rgb(255, 130, 0)",
}
const BackendRoot = process.env.VUE_APP_ROOT_API
const ApiRoot = BackendRoot + "/api"
const ClientApiRoot = BackendRoot + "/client-api"
const EmployeeApiRoot = BackendRoot + "/employee-api"
const ExternalApiRoot = BackendRoot + "/external-api"
const ImageRoot = BackendRoot + "/storage"
const ExcelRoot = BackendRoot + " /storage"

export const LSUriUser = "last_visited_uris_user_portal"
export const LSUriClient = "last_visited_uris_client_portal"
export const LSUriExternal = "last_visited_uris_external_portal"

export const appConfig = {
  checkVersionInterval:
    process.env.VUE_APP_CHECK_VERSION_INTERVAL || 1000 * 60 * 5, // default 5 mins
  toggleAllowLogin: true, // show/hide toggle allow login button for client-contact in client-detail-page
  showRefreshProfileButton: process.env.VUE_APP_MODE === "local", // show/hide json object for debug
  showJSON: process.env.VUE_APP_MODE === "local", // show/hide json object for debug
  showVersion:
    process.env.VUE_APP_MODE === "local" ||
    process.env.VUE_APP_MODE === "staging", // show/hide app version for debug
  showFDMockup: process.env.VUE_APP_MODE === "local", // show/hide json object for debug
  fetchNotifyDataForClientProtal: process.env.VUE_APP_MODE !== "local", // show/hide json object for debug
  image: ImageRoot,
  excel: ExcelRoot,
  idleTime: {
    maxTimeout: 60 * 1000 * 60, // 60 mins // Must be same value as Passport -> personalAccessTokensExpireIn() in AuthServiceProvider (ServerSide)
    threshold: 60 * 1000 * 10, // 10 mins // Period time to be able to call update new token before reach the maxTimeout
  },
  employeeApi: {
    employee: EmployeeApiRoot + "/employees",
    profile: EmployeeApiRoot + "/profile",
    me: EmployeeApiRoot + "/me",
    activitylog: EmployeeApiRoot + "/activitylogs",
    sharepoint: EmployeeApiRoot + "/sharepoints",
  },
  clientApi: {
    client: ClientApiRoot + "/clients",
    sharepoint: ClientApiRoot + "/sharepoints",
    feed: ClientApiRoot + "/feeds",
    profile: ClientApiRoot + "/profile",
    clientRm: ClientApiRoot + "/client-relationship-managers",
    location: ClientApiRoot + "/locations",
    clientContact: ClientApiRoot + "/client-contacts",
    service: ClientApiRoot + "/services",
    employingEntity: ClientApiRoot + "/employing-entities",
    invoice: ClientApiRoot + "/invoices",
    role: ClientApiRoot + "/roles",
    me: ClientApiRoot + "/me",
    ad: ClientApiRoot + "/ad-groups",
    document: ClientApiRoot + "/documents",
    country: ClientApiRoot + "/countries",
    employee: ClientApiRoot + "/employees",
    activitylog: ClientApiRoot + "/activitylogs",
    clientPc: ClientApiRoot + "/client-payroll-calendars",
  },
  api: {
    updateToken: ApiRoot + "/update-token",
    userLogin: ApiRoot + "/user-login",
    clientLogin: ApiRoot + "/client-login",
    clientEmployeeLogin: ApiRoot + "/client-employee-login",
    externalLogin: ApiRoot + "/external-login",
    logout: ApiRoot + "/logout",
    client: ApiRoot + "/clients",
    clientReport: ApiRoot + "/client-report",
    clientRm: ApiRoot + "/client-relationship-managers",
    clientPm: ApiRoot + "/client-payroll-managers",
    clientPc: ApiRoot + "/client-payroll-calendars",
    clientAddress: ApiRoot + "/client-addresses",
    clientContact: ApiRoot + "/client-contacts",
    clientService: ApiRoot + "/client-service",
    clientAttribute: ApiRoot + "/client-attributes",
    clientSharepoint: ApiRoot + "/client-sharepoint",
    clientIndividuals: ApiRoot + "/clients/individual",
    ubo: ApiRoot + "/ubos",
    country: ApiRoot + "/countries",
    service: ApiRoot + "/services",
    industry: ApiRoot + "/industries",
    icbSector: ApiRoot + "/icb-sectors",
    team: ApiRoot + "/teams",
    office: ApiRoot + "/offices",
    employingEntity: ApiRoot + "/employing-entities",
    user: ApiRoot + "/users",
    activitylog: ApiRoot + "/activitylogs",
    enum: ApiRoot + "/enums",
    ad: ApiRoot + "/ad-groups",
    invoice: ApiRoot + "/invoices",
    xero: ApiRoot + "/xero",
    clientXero: ApiRoot + "/client-xero",
    clientXpm: ApiRoot + "/client-xpm",
    fieldDefinition: ApiRoot + "/field_definitions",
    sharepoint: ApiRoot + "/sharepoints",
    currencyRate: ApiRoot + "/currency-rates",
    hubspot: ApiRoot + "/hubspots",
    clientChecklist: ApiRoot + "/client-checklist",
    clientDocument: ApiRoot + "/client-document",
    clientLocalInfo: ApiRoot + "/client-local-informations",
    clientLocalInfoNew: ApiRoot + "/client-local-info",
    clientLocalInfoFields: ApiRoot + "/client-local-info/fields",
    setting: ApiRoot + "/settings",
    queue: ApiRoot + "/job/status",
    release_note: ApiRoot + "/release-notes",
    feed: ApiRoot + "/feeds",
    stat: ApiRoot + "/stat",
    location: ApiRoot + "/locations",
    me: ApiRoot + "/me",
    adB2C: ApiRoot + "/ad-b2c",
    clientContactVendorStatus:
      ApiRoot + "/client-contact-vendor-statuses",
    clientUserVendorStatus: ApiRoot + "/client-user-vendor-statuses",
    clientPayrollContactStatus:
      ApiRoot + "/client-payroll-contact-statuses",
    document: ApiRoot + "/documents",
    documentSigner: ApiRoot + "/document-signers",
    employeeDirectory: ApiRoot + "/employee-directory",
    employee: ApiRoot + "/employees",
    professionalQualification: ApiRoot + "/professional-qualification",
    universityQualification: ApiRoot + "/university-qualification",
    bluemeg: ApiRoot + "/bluemeg",
    cas360: ApiRoot + "/cas360",
    embeddedCode: ApiRoot + "/embedded-code",
    deals: ApiRoot + "/deal",
    nexusInvoice: ApiRoot + "/invoice",
  },
  externalApi: {
    checkExternalToken: ExternalApiRoot + "/check-external-token",
    me: ExternalApiRoot + "/me",
    profile: ExternalApiRoot + "/profile",
    sharepoint: ExternalApiRoot + "/sharepoints",
    document: ExternalApiRoot + "/documents",
    activitylog: ExternalApiRoot + "/activitylogs",
  },
  azure: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    loginAuthority: process.env.VUE_APP_AZURE_AUTHORITY,
    passwordAuthority: process.env.VUE_APP_AZURE_AUTHORITY,
    knownAuthority: process.env.VUE_APP_AZURE_KNOWN_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_LOGOUT_REDIRECT_URI,
  },
  azure_b2c: {
    clientId: process.env.VUE_APP_AZURE_B2C_CLIENT_ID,
    loginAuthority: process.env.VUE_APP_AZURE_B2C_AUTHORITY,
    passwordAuthority: process.env.VUE_APP_AZURE_B2C_PASSWORD_AUTHORITY,
    knownAuthority: process.env.VUE_APP_AZURE_B2C_KNOWN_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_B2C_REDIRECT_URI,
    postLogoutRedirectUri:
      process.env.VUE_APP_AZURE_B2C_LOGOUT_REDIRECT_URI,
    validateAuthority: false,
  },
  endpoint: {
    boardManagement: process.env.VUE_APP_BOARD_MANAGEMENT_URL,
    itServiceDesk: "https://itsd.acclime.com",
    insytes:
      "https://acclimecom.sharepoint.com/sites/InformationTechnology/SitePages/Insytes.aspx",
    acclimeAcademy: "https://acclimecom.sharepoint.com/sites/Academy",
    book_a_desk:
      "https://acclimecom.sharepoint.com/sites/Acres/SitePages/BookDesk.aspx",
    business_hub: "https://acclimecom.sharepoint.com/sites/BusinessHub",
    it_hub: "https://acclimecom.sharepoint.com/sites/InformationTechnology/IT%20Documents/Forms/AllItems.aspx?viewid=3a8ce718-2206-49d9-b375-db41867668d3",
    acclime_payroll: "https://acclimecom.sharepoint.com/sites/AcclimePayroll",
  },
  redirect_urls: process.env.VUE_APP_WHITE_LIST_REDIRECT_URL
    ? process.env.VUE_APP_WHITE_LIST_REDIRECT_URL.split(",")
    : [],
  payroll: {
    employeeAdGroupId: process.env.VUE_APP_PAYROLL_EMPLOYEE_AD_GROUP_ID,
    contactAdGroupId: process.env.VUE_APP_PAYROLL_CONTACT_AD_GROUP_ID
  },
};
