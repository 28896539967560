import Vue from 'vue'
import VueI18n from "vue-i18n";
import en from './en.json'

Vue.use(VueI18n)

const languages = {
    en: en,
}

const messages = Object.assign(languages)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: messages
})

export default i18n
