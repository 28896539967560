import Vue from 'vue'
import auth from './services/auth'
import axios from 'axios'
import i18n from "./locales";
import store from './store'
import {
  getCurrentUser,
} from '@/utils';
import {
  appConfig
} from "./constants/config";

import router from './router'
import * as Sentry from "@sentry/vue";

const logout = (error) => {
  const user = getCurrentUser();
  auth.logout().then(() => {

    // remember last visit url
    localStorage.setItem("redirectUrl", window.location.href);

    Vue.$notify(
      "error",
      i18n.t("error.401"),
      error.response.data.errors || "Invalid credentials",
      {
        duration: 8000,
        permanent: false,
      }
    );
    if (user) {
      if (user.isUserPortal) {
        // Signing out from Microsoft Account.....
        // window.msal.signOut()
        window.location.href = "/auth/employee-login";
      } else if (user.isClientPortal) {
        // Signing out from Microsoft Account(B2C).....'
        window.msal2.signOut().then(() => {
          window.location.href = "/auth/login";
        });
      } else {
      }
    } else {
      if (error.response.data.portal && error.response.data.portal == "user") {
        // Signing out from Microsoft Account......
        // window.msal.signOut()
        window.location.href = "/auth/employee-login";
      } else if (
        error.response.data.portal &&
        error.response.data.portal == "client"
      ) {
        // Signing out from Microsoft Account(B2C)......
        window.msal2.signOut().then(() => {
          window.location.href = "/auth/login";
        });
      } else {
      }
    }
  });
}

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = auth.getAuthHeader();
  return config;
}, function (err) {
  return Promise.reject(err);
});

axios.interceptors.response.use(async function (response) {
  // manage idleTime cookies
  // create if not exist
  if (!Vue.$cookies.isKey('idleTime')) {
    Vue.$cookies.set('idleTime', Date.now());
  }
  const inThreshold = appConfig.idleTime.maxTimeout - appConfig.idleTime.threshold; // 
  const timePassed = Date.now() - Vue.$cookies.get('idleTime');

  const isGetVersionApi = response.data && response.data.data && response.data.data.name && response.data.data.name == "version" ? true : false;
  if (!isGetVersionApi) {
    // reset idleTime for all response except "get-version" api
    // console.log('Refresh idle time', response)
    Vue.$cookies.set('idleTime', Date.now());
  }

  const meta = router.currentRoute.meta
  if (meta && meta.loginRequired && !isGetVersionApi) {
    // renew token when timePassed reach inThreshold
    if (timePassed >= inThreshold) {
      console.log('Reach threshold => Renewing token...')
      auth.updateToken();
    }
  }
  return response;
}, function (error) {
  // Capture all API errors with Sentry
  if (error.response) {
    Sentry.captureException(error, {
      extra: {
        url: error.config.url,
        method: error.config.method,
        status: error.response.status,
        statusText: error.response.statusText,
        responseData: error.response.data,
        requestData: error.config.data,
        headers: error.config.headers,
      },
      tags: {
        'error.type': 'api',
        'error.status': error.response.status,
        'error.endpoint': error.config.url,
      },
      level: error.response.status >= 500 ? 'error' : 'warning'
    });
  } else if (error.request) {
    // Network error (no response received)
    Sentry.captureException(error, {
      extra: {
        url: error.config.url,
        method: error.config.method,
        message: 'Network Error - No response received',
      },
      tags: {
        'error.type': 'network',
      },
      level: 'error'
    });
  }

  // Continue with existing error handling
  if (401 === error.response.status) {
    store.dispatch('auth/me').then((resp) => {
      if (resp.data && resp.data.id) {
        Vue.$notify("error", i18n.t('error.401'), error.response.data.errors || error.response.data.message, {
          duration: 8000,
          permanent: false
        });
      } else {
        logout(error);
      }
    }).catch(err => {
      logout(error);
    });
  } else if (403 === error.response.status) {
    Vue.$notify("error", i18n.t('error.403'), error.response.data.errors || error.response.data.message, {
      duration: 8000,
      permanent: false
    });
  } else if (409 === error.response.status) {
    Vue.$notify("error", i18n.t('error.409'), error.response.data.errors || error.response.data.message, {
      duration: 8000,
      permanent: false
    });
  } else if (422 === error.response.status) {
    const errorMsg = error.response.data.errors;
    let msg = ''

    if (typeof errorMsg === 'object' && errorMsg !== null) {
      const messageValue = errorMsg.error?.message?.value;
      if (messageValue) {
        msg = messageValue;
      } else {
        const msgArr = Object.keys(errorMsg).map(function (key) {
          return errorMsg[key];
        });
        msg = msgArr.join('<br>');
      }
    } else {
      msg = errorMsg;
    }
    const h = document.createElement("div");
    const description = h.innerHTML = msg;

    Vue.$notify("error", i18n.t('error.422'), description, {
      duration: 8000,
      permanent: false
    });
  } else if (400 === error.response.status) {
    Vue.$notify("error", i18n.t('error.400'), error.response.data.errors || error.response.data.message, {
      duration: 8000,
      permanent: false
    });
  } else if (301 === error.response.status) {
    Vue.$notify("error", i18n.t('error.301'), error.response.data.errors || error.response.data.message, {
      duration: 8000,
      permanent: false
    });
  } else if (500 === error.response.status) {
    Vue.$notify("error", i18n.t('error.500'), error.response.data.errors || error.response.data.message || error.response.data.error, {
      duration: 8000,
      permanent: false
    });
  } else {
    Vue.$notify("error", i18n.t('error.any', {
      title: error.response.status
    }), error.response.data.errors, {
      duration: 8000,
      permanent: false
    });
  }

  return Promise.reject(error);
});