import Axios from "axios";
import { appConfig } from "../constants/config";
var qs = require('qs')

var activitylog = {
    async getItems(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "?" + queryString);
    },
    async getItem(payload) {
        return await Axios.get(appConfig.api.activitylog + "/" + payload.id);
    },
    async create(payload) {
        return await Axios.post(appConfig.api.activitylog, payload);
    },
    async update(payload) {
        return await Axios.put(appConfig.api.activitylog + "/" + payload.id, payload);
    },
    async delete(payload) {
        return await Axios.delete(appConfig.api.activitylog + "/" + payload.id);
    },
    async reverse(payload) {
        return await Axios.post(appConfig.api.activitylog + "/reverse/" + payload.id, payload);
    },
    async getAllClients(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "/clients?" + queryString);
    },
    async getActivityUsers(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "/activity-users?" + queryString);
    },
    async getNoneActivityUsers(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "/none-activity-users?" + queryString);
    },
    async getActivityUserContacts(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "/activity-user-contacts?" + queryString);
    },
    async getNoneActivityUserContacts(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.activitylog + "/none-activity-user-contacts?" + queryString);
    },
}

export default activitylog;
