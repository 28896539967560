import Vue from 'vue'
import App from './App'
import {
  appConfig
} from '@/constants/config'

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue'
// Router & Store add
import router from './router'
import store from './store'
import i18n from "./locales";
import moment from 'moment';

// cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '1h', path: '/', domain: '', secure: 'true', sameSite: 'Strict'})

// Notification Component Add
import Notifications from './components/Common/Notification'
// Breadcrumb Component Add
import Breadcrumb from './components/Common/Breadcrumb'
// RefreshButton Component Add
import RefreshButton from './components/Common/RefreshButton'
// Colxx Component Add
import Colxx from './components/Common/Colxx'
// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
import VueLineClamp from 'vue-line-clamp'
import VueScrollTo from 'vue-scrollto'
import intercept from './services/intercept';

import {
  getCurrentLanguage,
  hasPermission
} from './utils'
import bootstrap from "./bootstrap";

// MSAL
import { MsalPlugin, MsalPlugin2 } from './plugins/msal-plugin';

Vue.use(new MsalPlugin(), appConfig.azure);
Vue.use(new MsalPlugin2(), appConfig.azure_b2c);

// vee-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('odd', {
  validate: value => {
    return value % 2 !== 0;
  },
  message: 'This field must be an odd number'
});

extend('unique', {
  validate: (value, list) => {
    const name = value ? value.toLowerCase() : ''
    return list.find(x => x.name.toLowerCase() === name) ? false : true
  },
  message: 'This value is duplicated!'
});

extend('unique-other', {
  params: ['list', 'oldValue'],
  validate: (value, {
    list,
    oldValue
  }) => {
    const name = value ? value.toLowerCase() : ''
    const oldName = oldValue ? oldValue.toLowerCase() : ''
    if (oldName === name) {
      return true
    } else {
      return list.find(x => x.name.toLowerCase() === name) ? false : true
    }
  },
  message: '{_field_} is duplicated!'
});

extend('unique-lastname', {
  validate: (value, list) => {
    const name = value ? value.toLowerCase() : ''
    return list.find(x => x.last_name.toLowerCase() === name) ? false : true
  },
  message: 'This last name is duplicated!'
});

extend('not-special', {
  validate: (value) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(value) ? false : true
  },
  message: 'This value must not contain any special characters!'
});

extend('sharepoint-title', {
  validate: (value) => {
    const format = /[~"#%&*:<>?\/\\{|}]/;
    return format.test(value) ? false : true
  },
  message: 'This value must not contain any special characters!'
});

extend('max-files', {
  validate: (value, maxFiles) => {
    return value.length > maxFiles ? false : true
  },
  message: 'You can not upload more than 10 documents at one time',

});

extend('sp-ext', {
  ...rules.ext,
  message: 'The {_field_}\'s extension must be bmp, gif, jpg, png, doc, docx, xls, xlsx, ppt, pptx, pdf, odt, txt, text, rtf, zip',
});

extend('sp-image', {
  ...rules.ext,
  message: 'The {_field_}\'s extension must be jpg, png',
});

extend('sp-ext-acclime-sign', {
  ...rules.ext,
  message: 'The {_field_}\'s extension must be pdf',
});

extend('sp-size', {
  ...rules.size,
  message: 'The {_field_} field size must be less than 20MB',
});

extend('sp-size-image', {
  ...rules.size,
  message: 'The {_field_} field size must be less than 2MB',
});

localize('en', en);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import PageHeader from "/src/components/Common/PageHeader";
Vue.component('page-header', PageHeader);

import ClientHeader from "/src/components/ClientDetail/ClientHeader";
Vue.component('client-header', ClientHeader);

import SubMenu from "/src/containers/navs/SubMenu";
Vue.component('sub-menu', SubMenu);

import StepMenu from "/src/containers/navs/StepMenu";
Vue.component('step-menu', StepMenu);

// custom directive
Vue.directive('can', {
  inserted: function (el, binding, vnode) {
    // console.log(binding)
    var isOrCase = (typeof binding.arg !== 'undefined') ? 1 : 0;
    // let expression = JSON.parse(binding.expression.replace(/'/g, '"'));
    let expression = binding.value;
    !hasPermission(expression, isOrCase) && (vnode.elm.remove());
  },
})

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(require('vue-shortkey'));
Vue.use(contentmenu);
Vue.use(VueScrollTo);
Vue.use(VueLineClamp, {
  importCss: true
});

Vue.component('piaf-breadcrumb', Breadcrumb);
Vue.component('b-refresh-button', RefreshButton);
Vue.component('b-colxx', Colxx);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('D MMM YYYY')
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('D MMM YYYY, H:mm')
  }
})

import _ from 'lodash'
Vue.filter('unescape', function (value) {
  if (value) {
    return _.unescape(value)
  }
})

window.controllers = [];

import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "https://bd039e474a3f382248bc259c6b0af31e@o4508403188236288.ingest.us.sentry.io/4508408548491264",
  integrations: [],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

const agility = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

router.onError((error) => {
  Sentry.captureException(error);
});

store.subscribeAction({
  error: (action, state, error) => {
    Sentry.captureException(error, {
      extra: {
        action: action.type,
        state: state,
      },
    });
  },
});

// interval check version on server
intercept.checkVersion(agility); // fire on first-load
let checkVersionInterval = setInterval(() => {
  intercept.checkVersion(agility);
}, appConfig.checkVersionInterval);

export default agility
