<template>
  <div @click.stop="()=>{}">
    <div v-for="(item) in filteredMenuItems(menuItems)"
      :class="{'list-unstyled': true, 'd-block': (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id }"
      :data-parent="item.id" :key="`sub_${item.id}`">
      <div class="float-left pr-2 mr-2" v-for="(sub, subIndex) in filteredMenuItems(item.submenus)"
        :key="`sub_${subIndex}`"
        :id="sub.id"
        :class="{'sep-right': subIndex + 1 !== filteredMenuItems(item.submenus).length, 'has-sub-item': sub.subs && sub.subs.length > 0, 'active': $route.path.indexOf(sub.to) > -1}">
        
        <b-link class="submenu text-upper text-bold d-flex align-items-center" :to="sub.to.replace(':id', $route.params.id)">
          <i :class="[sub.icon, {'hide-menu-icon': isInfoMissingData(sub) || isContactMissingData(sub) || isRmMissingData(sub) || isInvoiceMissingData(sub) || isDocumentMissingData(sub) || (sub.label == 'menu.client-bd' && !isHubspotLink())}]" style="position: relative; font-size: 17px;">
            <div class="missing-data box-overlay" v-if="isInfoMissingData(sub) || isContactMissingData(sub) || isRmMissingData(sub) || isInvoiceMissingData(sub)"></div>
            <div :class="`missing-data ${isDocumentMissingData(sub)} box-overlay`" v-if="isDocumentMissingData(sub)"></div>
            <div :class="`missing-data low box-overlay`" v-if="sub.label == 'menu.client-bd' && !isHubspotLink()"></div>
          </i>
          <div class="ml-1 mr-1" :class="{'active': $route.path.indexOf(sub.to) > -1}">{{ $t(sub.label) }}</div>
          <b-badge v-if="totalCounter" variant="danger" class="rounded-pill ml-auto">{{totalCounter < 100 ? "99+" : totalCounter }}</b-badge>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
  } from "vuex";
  import menu from "../../constants/menu";
  import {
    isClientPortal,
    isUserPortal,
    hasPermission
  } from "../../utils";

  export default {
    props: ['menuParentId', 'totalCounter','componentName', 'hasMyClient', 'missingData', 'isCas360', 'isBluemeg', 'hasBankDetails', 'hasApprovalRights', 'isHubspot', 'isBluemegCompat', 'isCas360Compat'],
    data() {
      return {
        selectedParentMenu: "",
        viewingParentMenu: "",
      };
    },
    mounted() {
    },
    beforeDestroy() {
    },

    methods: {
      //For UserRole
      filteredMenuItems(menuItems) {
        if (!this.hasMyClient) {
          menuItems = menuItems.filter(x => x.label !== 'menu.my-client');
        }

        let cas360 = this.isCas360 ? true : false;
        let bluemeg = this.isBluemeg ? true : false;
        let hasBankDetails = this.hasBankDetails ? true : false;
        let hasApprovalRights = this.hasApprovalRights ? true : false;
        let showCorporateMenu = this.isBluemegCompat || this.isCas360Compat ? true : false;


        menuItems = menuItems.filter((x) => {
          return x.id == undefined
            || (
              x.id != 'menu-client-corporate-secretary'
              && x.id != 'menu-client-bank-details'
              && x.id != 'menu-client-approval-rights'
            )
            || (x.id == 'menu-client-corporate-secretary' && ((cas360 || bluemeg) || showCorporateMenu))
            || (x.id == 'menu-client-bank-details' && hasBankDetails)
            || (x.id == 'menu-client-approval-rights' && hasApprovalRights);
        });
        return menuItems ?
          menuItems.filter(
            (x) => hasPermission(x.permission, x.anyPermission)
          ) : [];
      },

      isInfoMissingData(menu) {
        const toCheckfields = [
            'name',
            'company-type',
            'jurisdiction-of-incorporation',
            'date-of-incorporation',
            'business-status',
            'sector',
            'client-since',
            'service'];
        return menu.label === 'menu.client-info' &&
          this.missingData.some(x=> {
            return toCheckfields.includes(x)
          }) ? true : false
      },
      isContactMissingData(menu) {
        return menu.label === 'menu.client-contact' && this.missingData.includes('contact') ? true : false
      },
      isRmMissingData(menu) {
        return menu.label === 'menu.client-rm' && this.missingData.includes('rm') ? true : false
      },
      isInvoiceMissingData(menu) {
        return menu.label === 'menu.client-invoice' && this.missingData.includes('xero') ? true : false
      },
      isDocumentMissingData(menu) {
        return menu.label === 'menu.client-document' ? this.missingData.includes('document-high') ? 'high' : this.missingData.includes('document-medium') ? 'medium' : this.missingData.includes('document-low') ? 'low' : null : null;
      },
      isHubspotLink() {
        return this.isHubspot ? true : false
      }
    },

    computed: {
      ...mapGetters({
        isClientPortal: "auth/isClientPortal", // <= not reactive
      }),
      menuItems() {
        return isUserPortal() ? menu.userMenu.filter(x => x
          .id === this.menuParentId) : isClientPortal() ? menu.clientMenu.filter(x => x.id === this.menuParentId) : menu.externalMenu.filter(x => x.id === this.menuParentId)
      },
    },
    watch: {
    },
  };
</script>

<style nonce="kbuIoOUjR2s3txQqq71n" lang="scss">
  .box-overlay {
    position: absolute;
    top: 1px;
    left: -1px;
    margin-top: 0 !important;
    margin-left: 0 !important;
    &:before { width: 12px; height: 12px; }
  }
  .hide-menu-icon {
    &:before { color: transparent; }
  }
</style>