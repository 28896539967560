import Axios from "axios";
import { appConfig } from "../../../constants/config"
var qs = require('qs')

var activitylog = {
    async getItems(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.employeeApi.activitylog + "?" + queryString);
    },
    async getItem(payload) {
        return await Axios.get(appConfig.employeeApi.activitylog + "/" + payload.id);
    },
    async create(payload) {
        return await Axios.post(appConfig.employeeApi.activitylog, payload);
    },
    async update(payload) {
        return await Axios.put(appConfig.employeeApi.activitylog + "/" + payload.id, payload);
    },
    async delete(payload) {
        return await Axios.delete(appConfig.employeeApi.activitylog + "/" + payload.id);
    },
}

export default activitylog;
