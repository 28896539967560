import Axios from "axios"
import { appConfig } from "../constants/config"
import store from "../store"
import { getCurrentUser } from "@/utils"

var intercept = {
  async checkVersion(vm) {
    const CACHE_DURATION = 1000 * 60 * 60 * 3; // 3 hours
    const lastCheckTime = localStorage.getItem("version_last_check");
    const now = Date.now();

    if (!lastCheckTime || (now - parseInt(lastCheckTime)) > CACHE_DURATION) {
      const resp = await Axios.get(appConfig.api.setting + "/get-version");
      const serverVersion = resp.data.data.value || null;
      localStorage.setItem("version_last_check", now.toString());
      localStorage.setItem("server_version", serverVersion);
    }

    const serverVersion = localStorage.getItem("server_version");
    let clientVersion = localStorage.getItem("app_version") || null;
    if (clientVersion === null) {
      // set new app_version
      clientVersion = serverVersion
      localStorage.setItem("app_version", serverVersion) // update app version
    }
    if (serverVersion !== clientVersion) {
      // App version is out of date!
      localStorage.setItem("app_version", serverVersion) // update app version
      const user = getCurrentUser()
      if (store.getters["auth/isLogged"]) {
        store.dispatch("auth/logout").then(() => {
          if (user && user.isUserPortal) {
            // Signing out from Microsoft Account....
            // vm.$msal.signOut()
          } else if (user && user.isClientPortal) {
            // Signing out from Microsoft Account(B2C)....
            vm.$msal2.signOut()
          } else {
            window.location = "/"
          }
        })
      } else {
        // App is reloading...
        location.replace("/auth/login")
      }
    }
  },
}

export default intercept
