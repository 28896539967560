<template>
  <span>
    <h1 v-if="heading && heading.length>0">{{ heading }}</h1>
    <b-nav class="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
      <b-breadcrumb :items="items" style="padding: 0; text-transform: inherit; font-size: 14px;" />
    </b-nav>
  </span>
</template>

<script>
  import {
    adminRoot
  } from "../../constants/config";
  export default {
    props: ["heading", "itemsProp"],
    data() {
      return {
        items: []
      };
    },
    methods: {
      getUrl(path, sub, index) {
        return "/" + path.split(sub)[0] + sub;
      }
    },
    mounted() {
      if (this.itemsProp && this.itemsProp.length) {
        this.items.push({text: this.$t("menu.home"), to: adminRoot})
        this.items = this.items.concat(this.itemsProp);
      } else {
        let path = this.$route.path.substr(1);
        let rawPaths = path.split("/");

        for (var pName in this.$route.params) {
          if (rawPaths.includes(this.$route.params[pName])) {
            rawPaths = rawPaths.filter(x => x !== this.$route.params[pName]);
          }
        }
        rawPaths.map((sub, index) => {
          this.items.push({
            text: "/" + sub !== adminRoot ?
              this.$t("menu." + sub) :
              this.$t("menu.home"),
            to: this.getUrl(path, sub, index)
          });
        });
      }
    }
  };
</script>