import Vue from "vue"
import i18n from "../locales"
import { isAuthGuardActive } from "../constants/config"
import {
  setCurrentUser,
  getCurrentUser,
  setCurrentEmployee,
  getCurrentEmployee,
  getCurrentUserPermissionNames,
  hasPermission,
  isHostnameInRedirectWhitelist,
  getRedirectUrlforUserPortal,
  getRedirectUrlforClientPortal,
  getRedirectUrlforExternalPortal
} from "."
import { setLSWithExpiry } from "./utils"
import {
  adminRoot,
  clientRoot,
  externalRoot,
  appConfig,
} from "../constants/config"
import activitylogServiceUser from "@/services/activitylog";
import activitylogServiceClient from "@/services/client_portal/activitylog";
import activitylogServiceClientEmployee from "@/services/client_portal/employee/activitylog";
import activitylogServiceExternal from "@/services/external_portal/activitylog";

const delay = ms => new Promise(res => setTimeout(res, ms));

export default (to, from, next) => {
  const user = getCurrentUser()

  // abort all request-signals
  window.controllers.forEach(x => x && x.abort())

  // call api to create activily log 
  if (user && (user.isUserPortal || user.isClientPortal || user.isExternalPortal)) {
    const payload = {
      description: `Path ${to.path}`,
      properties: { attributes: { url: window.location.origin + to.fullPath } },
      name: `Navigation`,
      event: `navigated`
    }
    const activitylogService = user.isClientPortal ? user.isContact ? activitylogServiceClient : activitylogServiceClientEmployee : user.isExternalPortal ? activitylogServiceExternal : activitylogServiceUser
    activitylogService.create(payload).then((res) => {
      // console.log('create log res: ', res)
    }).catch(err => { console.log('log error caught: ', err) });
  }

  // check redirectUrl
  if (to.path === "/") {
    // let user = getCurrentUser()
    console.log("redirect url: ", to.query.redirectUrl)
    if (
      to.query &&
      to.query.redirectUrl &&
      isHostnameInRedirectWhitelist(to.query.redirectUrl)
    ) {
      localStorage.setItem("redirectUrl", to.query.redirectUrl)
      if (user) {
        if (user.isUserPortal) {
          const redirectUrl = getRedirectUrlforUserPortal();
          if (redirectUrl) { 
            window.location = redirectUrl; 
          }
        } else if (user.isClientPortal) {
          const redirectUrl = getRedirectUrlforClientPortal();
          if (redirectUrl) { 
            setLSWithExpiry("redirectUrlConsent", redirectUrl, 600000)
            window.location = redirectUrl; 
          }
        } else {
          const redirectUrl = getRedirectUrlforExternalPortal();
          if (redirectUrl) { 
            window.location = redirectUrl; 
          }
        }

      } else {
        return user && user.isUserPortal
          ? next(adminRoot)
          : user && user.isClientPortal
            ? next(clientRoot)
            : next(externalRoot)
      }
    } else {
      return user && user.isUserPortal
        ? next(adminRoot)
        : user && user.isClientPortal
          ? next(clientRoot)
          : next(externalRoot)
    }
  }

  if (to.matched.some((record) => record.meta.loginRequired)) {
    if (isAuthGuardActive) {
      // const user = getCurrentUser()
      if (user) {
        if (to.matched.some((record) => record.meta.isUserPortal)) {
          // guard for user portal
          if (user && user.isUserPortal) {
            if (hasPermission(to.meta.permissions)) {
              next()
            } else {
              if (from.path === "/auth/login") setCurrentUser(null)
              Vue.$notify(
                "error",
                i18n.t("error.403"),
                i18n.t("error.no-permission", {
                  title: to.meta.permissions,
                }),
                {
                  duration: 3000,
                  permanent: false,
                },
              )
              next(from.path)
            }
          } else {
            // is not 'api' guard
            setCurrentUser(null)
            next("/auth/employee-login")
          }
        } else if (to.matched.some((record) => record.meta.isClientPortal)) {
          // guard for client portal
          if (user) {
            // check accept_agreement
            if (!user.accept_agreement && to.name != "consent" && user.isContact) {
              next(`/client/consent/${user.id}`)
            }
          }
          if (user && user.isClientPortal) {
            if (hasPermission(to.meta.permissions)) {
              next()
            } else {
              Vue.$notify(
                "error",
                i18n.t("error.403"),
                i18n.t("error.no-permission", {
                  title: to.meta.permissions,
                }),
                {
                  duration: 3000,
                  permanent: false,
                },
              )
              next(from.path)
            }
          } else {
            // is not 'client' gaurd
            setCurrentUser(null)
            setCurrentEmployee(null)
            // next('/auth/login')
          }
        } else {
          // guard for external portal
          if (user && user.isExternalPortal) {
            if (hasPermission(to.meta.permissions)) {
              next()
            } else {
              if (from.path === "/auth/external-login") setCurrentUser(null)
              Vue.$notify(
                "error",
                i18n.t("error.403"),
                i18n.t("error.no-permission", {
                  title: to.meta.permissions,
                }),
                {
                  duration: 3000,
                  permanent: false,
                },
              )
              next(from.path)
            }
          } else {
            // is not 'api' guard
            setCurrentUser(null)
            next("/auth/external-login")
          }
        }
      } else {
        setCurrentUser(null)
        if (to.matched.some((record) => record.meta.isUserPortal)) {
          console.log("user portal")
          if (window.location.pathname !== "/") {
            console.log('Fallback from unauthenticate non-user portal guard')
            // remember last visit url
            localStorage.setItem("redirectUrl", window.location.href);
          }
          next("/auth/employee-login")
        } else {
          console.log("non user portal")
          if (window.location.pathname !== "/") {
            console.log('Fallback from unauthenticate non-user portal guard')
            // remember last visit url
            localStorage.setItem("redirectUrl", window.location.href);
          }
          next("/auth/login")
        }
      }
    } else {
      next()
    }
  } else {
    next()
  }
}
