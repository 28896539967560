import AuthService from "../../services/auth"
import Cookies from "js-cookie";

// console.log('cookies', Cookies.get('user'));
// const user = Cookies.get('user') ? JSON.parse(Cookies.get("user")) : null;
// const access_token = Cookies.get("access_token")
// const employee = Cookies.get('employee') ? JSON.parse(Cookies.get("employee")) : null;
// const employee_access_token = Cookies.get("employee_access_token")

const user = JSON.parse(localStorage.getItem("user"))
const access_token = Cookies.get("access_token")
const employee = JSON.parse(localStorage.getItem("employee"))
const employee_access_token = Cookies.get("employee_access_token")

const initialState = user
  ? {
      status: { loggedIn: true },
      user,
      access_token: access_token,
      employee: employee,
      employee_access_token: employee_access_token,
    }
  : {
      status: { loggedIn: false },
      user: null,
      access_token: null,
      employee: null,
      employee_access_token: null,
    }

const state = initialState

const getters = {
  isLogged(state) {
    return state.status.loggedIn
  },
  getUser(state) {
    return state.user
  },
  isUserPortal(state) {
    return state.user && state.user.isUserPortal ? true : false
  },
  isClientPortal(state) {
    return state.user && state.user.isClientPortal ? true : false
  },
  isExternalPortal(state) {
    return state.user && state.user.isExternalPortal ? true : false
  },
}

const actions = {
  userLogin({ commit }, user) {
    return AuthService.userLogin(user).then(
      (user) => {
        commit("LOGIN_SUCCESS", user)
        return Promise.resolve(user)
      },
      (error) => {
        commit("LOGIN_FAILURE")
        return Promise.reject(error)
      },
    )
  },
  clientLogin({ commit }, user) {
    return AuthService.clientLogin(user).then(
      (user) => {
        commit("LOGIN_SUCCESS", user)
        return Promise.resolve(user)
      },
      (error) => {
        commit("LOGIN_FAILURE")
        return Promise.reject(error)
      },
    )
  },
  externalLogin({ commit }, user) {
    return AuthService.externalLogin(user).then(
      (user) => {
        commit("LOGIN_SUCCESS", user)
        return Promise.resolve(user)
      },
      (error) => {
        commit("LOGIN_FAILURE")
        return Promise.reject(error)
      },
    )
  },
  logout({ commit }) {
    return AuthService.logout().then(() => {
      commit("LOGOUT")
      return Promise.resolve()
    })
  },
  setProfile({ commit }, user) {
    commit("LOGIN_SUCCESS", user)
    return Promise.resolve()
  },
  me() {
    return AuthService.me().then((me) => {
      return Promise.resolve(me)
    })
  },
}

const mutations = {
  LOGIN_SUCCESS(state, user) {
    state.status.loggedIn = true
    state.user = user.user
    state.access_token = user.access_token
    state.employee = user.employee
    state.employee_access_token = user.employee_access_token
  },
  LOGIN_FAILURE(state) {
    state.status.loggedIn = false
    state.user = null
    state.access_token = null
    state.employee = null
    state.employee_access_token = null
  },
  LOGOUT(state) {
    state.status.loggedIn = false
    state.user = null
    state.access_token = null
    state.employee = null
    state.employee_access_token = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
