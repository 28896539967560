import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import app from '../main'
import auth from './modules/auth'
import menu from './modules/menu'
import queue from './modules/queue'

import { setCurrentLanguage } from '../utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload
      setCurrentLanguage(payload);
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    menu,
    auth,
    queue,
  },
	// plugins: [createPersistedState({ paths: ['menu', 'auth', 'queue'] })],
})
