<template>
  <div class="page-header-box">
    <b-row>
      <b-colxx>
        <div class="float-none clearfix">
          <piaf-breadcrumb :itemsProp="breadcrumbItems" v-if="dataItem.id" />
          <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner spinner-variant="primary"
            class="d-inline-block">
            <div class="clearfix" style="height: 24.5px;" v-if="!dataItem.id"></div>
          </b-overlay>
        </div>
        <div class="d-block d-md-inline-block float-md-left">
          <h2 :class="`mb-0 acclime-dot-tail-disabled page-title ${dataItem.record_status}`"
            id="tooltip-target-client-name">{{dataItem.name}}</h2>
          <div class="missing-data-quality-info" v-if="getMissingData">
            <div class="missing-data-quality-info-inner" v-html="getMissingData"></div>
          </div>
          <div class="mb-4" style="font-size: 14px; color: #666;">
            <div class="float-left mr-3"><i class="simple-icon-globe mr-2" />
              <b-link :href="`${Utils.validateUrl(clientWebUrl)}`" target="_blank" v-if="dataItem.website_url">{{clientWebUrl}}</b-link>
              <span v-else>{{clientWebUrl}}</span>
            </div>
            <div class="float-left mr-3" style="text-transform: capitalize;"><i
                class="simple-icon-location-pin mr-2" />{{clientAddress}}</div>
            <div class="float-left"><i class="simple-icon-clock mr-2" />{{clientLastUpdate | formatDateTime }}</div>
          </div>
        </div>
      </b-colxx>
    </b-row>
    <b-row class="mt-2">
      <b-colxx class="mt-2">
        <sub-menu
          :menuParentId="menuParentId"
          :missingData="missingData"
          :isCas360="isCas360"
          :isBluemeg="isBluemeg"
          :hasBankDetails="hasBankDetails"
          :hasApprovalRights="hasApprovalRights"
          :isHubspot="isHubspot"
          :isBluemegCompat="isBluemegCompat"
          :isCas360Compat="isCas360Compat"
        />
      </b-colxx>
    </b-row>
  </div>
</template>

<script nonce='kbuIoOUjR2s3txQqq71n'>
  import {
    appConfig
  } from "@/constants/config";
  import * as Utils from '@/utils/utils';
  import moment from 'moment';
  import sharepointService from "@/services/sharepoint";

  export default {
    name: "ClientHeader",
    components: {},
    props: ['dataItem', 'breadcrumbItems', 'menuParentId', 'busy'],
    data() {
      return {
        appConfig: appConfig,
        Utils: Utils,
        fd: Utils.fd(),
        allDocuments: [],
      }
    },
    created() {
      // if (this.$route.name !== "client-documents") {
      //   this.getAllDocumentsTypes()
      // }
      this.$root.$on("getAllDocumentsTypes", () => {
        console.log('from mounted')
        this.getAllDocumentsTypes();
      });
    },
    mounted() {
      // this.$root.$on("getAllDocumentsTypes", () => {
      //   console.log('from mounted')
      //   this.getAllDocumentsTypes();
      // });
    },
    methods: {
      containSubmenu(data, submenu) {
        let found = false;
        if(data){
          for (const row in data) {
            if(typeof(data[row]['submenu']) != 'undefined' && data[row]['submenu'] !== null){
              if(data[row]['submenu']['submenu_name'] == submenu){
                //console.log('found A');
                found = true;
                break;
              }
            }

            if(typeof(data[row]['items']) != 'undefined'){
              let r = this.containSubmenu(data[row]['items'], submenu);
              if(r === true){
                //console.log('found B');
                found = true;
                break;
              }
            }
          }
        }

        return found;
      },
      // documents
      async getDocumentsByType(document_type) {
        console.log('header: ', document_type)
        try {
          const resp = await sharepointService.getDocumentsByType({
            id: this.dataItem.id,
            document_type: document_type
          })
          this.allDocuments = this.allDocuments.concat(resp.data);
        } catch (err) {
        }
      },

      async getAllDocumentsTypes() {
        this.allDocuments = [];
        if (this.dataItem.documents) {
          // await Promise.all(this.dataItem.documents.map(async (x) => {
          //   await this.getDocumentsByType(x.document_type);
          // }));
          this.dataItem.documents.map((x) => {
            this.getDocumentsByType(x.document_type);
          });
        }
      },
    },
    computed: {
      missingData() {
        // return Utils.missingData(this.dataItem).concat(this.getDocumentQuality); // use document qulity
        return Utils.missingData(this.dataItem); // not use document qulity anymore
      },
      getMissingData() {
        let item = this.dataItem;
        let message = "<span style='font-weight: bold;'>Missing:</span><br>";
        let missingData = [];
        Utils.missingData(this.dataItem).forEach(x => {
          missingData.push(this.$tc('field.' + x, 1))
        });
        // not use document qulity anymore
        // const lastDocumentUpdate = this.getLastDocumentUpdate
        // lastDocumentUpdate && missingData.push(lastDocumentUpdate);
        return missingData.length ? message + missingData.join("<br>") : null;
      },
      getDocumentQuality() {
        if (!this.allDocuments.length) {
          return ['document-low']
        } else if (this.getLastDocumentUpdate) {
          return ['document-medium']
        } else {
          return ['document-high']
        }
      },
      getLastDocumentUpdate() {
        const sortedDocuments = this.allDocuments.sort(function(a, b) {
          var keyA = new Date(a.modified),
            keyB = new Date(b.modified);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        const lastDocumentUpdate = sortedDocuments && sortedDocuments.length ? sortedDocuments[0].modified : null;
        if (!lastDocumentUpdate) return `Last document update: No document assigned`;
        const lastDocumentUpdateObj = moment(lastDocumentUpdate)
        const datetime3monthsAgoObj = moment().subtract(3, 'months')
        // console.log('doc: ', lastDocumentUpdateObj.utc().format())
        // console.log('now-mins: ', datetime3monthsAgoObj.utc().format())
        if (lastDocumentUpdateObj.isBefore(datetime3monthsAgoObj)) {
          // not updated since 3 months
          return `Last document update: ${Utils.formatDayMonthYearTime(lastDocumentUpdate)}`;
        }
        return null;
      },
      clientWebUrl() {
        return this.dataItem.website_url || 'No website'
      },
      clientAddress() {
        const address = this.dataItem.client_addresses && this.dataItem.client_addresses.length ? this.dataItem
          .client_addresses[0] : null;
        const city = address ? address.city : 'no city';
        const country = address ? address.country ? address.country.name : '' : 'no country';
        let pin = [];
        pin.push(city);
        pin.push(country);
        return pin.join(', ');
      },
      isCas360(){
        return this.dataItem.cas360 && this.dataItem.cas360.length > 0;
      },
      isBluemeg(){
        return this.dataItem.bluemeg && this.dataItem.bluemeg.length > 0;
      },
      isHubspot(){
        return this.dataItem.hubspot ? true : false;
      },
      hasBankDetails(){
        const r = this.containSubmenu(this.dataItem.client_local_info, 'bank_details');
        //console.log('hasBankDetails', r);
        return r;
      },
      hasApprovalRights(){
        const r = this.containSubmenu(this.dataItem.client_local_info, 'approval_rights');
        //console.log('hasApprovalRights', r);
        return r;
      },
      clientLastUpdate() {
        return this.dataItem.last_update_at;
      },
      isBluemegCompat(){
        return this.dataItem.bluemeg_compatible ? true : false;
      },
      isCas360Compat(){
        return this.dataItem.cas360_compatible ? true : false;
      },
    }
  }
</script>
