import Axios from "axios";
import { appConfig } from "../constants/config";
var qs = require('qs')

var queue = {
    async getJobs(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.queue + "?" + queryString);
    },
    async getJobBatch(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.queue + "/batch?" + queryString);
    },
    async getActiveJobBatch(params) {
        let queryString = qs.stringify(params, {encode:false});
        return await Axios.get(appConfig.api.queue + "/active?" + queryString);
    },
}

export default queue;
