import {
  appConfig,
  defaultDirection,
  defaultColor,
  themeSelectedColorStorageKey,
  themeRadiusStorageKey,
  localeOptions,
  defaultLocale,
  adminRoot,
  clientRoot,
  externalRoot,
  LSUriUser, LSUriClient
} from "../constants/config"
import Cookies from 'js-cookie';
import axios from "axios"
import moment from "moment"

export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    var A = a[key]
    var B = b[key]
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }
  return dd + "." + mm + "." + yyyy
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ":" + now.getMinutes()
}

export const ThemeColors = () => {
  let rootStyle = getComputedStyle(document.body)
  return {
    themeColor1: rootStyle.getPropertyValue("--theme-color-1").trim(),
    themeColor2: rootStyle.getPropertyValue("--theme-color-2").trim(),
    themeColor3: rootStyle.getPropertyValue("--theme-color-3").trim(),
    themeColor4: rootStyle.getPropertyValue("--theme-color-4").trim(),
    themeColor5: rootStyle.getPropertyValue("--theme-color-5").trim(),
    themeColor6: rootStyle.getPropertyValue("--theme-color-6").trim(),
    themeColor1_10: rootStyle.getPropertyValue("--theme-color-1-10").trim(),
    themeColor2_10: rootStyle.getPropertyValue("--theme-color-2-10").trim(),
    themeColor3_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
    themeColor4_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
    themeColor5_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
    themeColor6_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
    primaryColor: rootStyle.getPropertyValue("--primary-color").trim(),
    foregroundColor: rootStyle.getPropertyValue("--foreground-color").trim(),
    separatorColor: rootStyle.getPropertyValue("--separator-color").trim(),
  }
}

export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const getDirection = () => {
  let direction = defaultDirection
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction")
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  }
}

export const setDirection = (localValue) => {
  let direction = "ltr"
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue
  }
  localStorage.setItem("direction", direction)
}

export const getThemeColor = () => {
  let color = defaultColor
  try {
    if (localStorage.getItem(themeSelectedColorStorageKey)) {
      color = localStorage.getItem(themeSelectedColorStorageKey) || defaultColor
    }
  } catch (error) {
    color = defaultColor
  }
  return color
}

export const setThemeColor = (color) => {
  try {
    localStorage.setItem(themeSelectedColorStorageKey, color)
  } catch (error) {}
}

export const getThemeRadius = () => {
  let radius = "rounded"
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      radius = localStorage.getItem(themeRadiusStorageKey) || "rounded"
    }
  } catch (error) {
    radius = "rounded"
  }
  return radius
}

export const setThemeRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius)
  } catch (error) {}
}

export const getCurrentLanguage = () => {
  let locale = defaultLocale
  try {
    if (
      localStorage.getItem("currentLanguage") &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem("currentLanguage"),
      ).length > 0
    ) {
      locale = localStorage.getItem("currentLanguage")
    }
  } catch (error) {
    locale = defaultLocale
  }
  return locale
}

export const setCurrentLanguage = (lang) => {
  try {
    localStorage.setItem("currentLanguage", lang)
  } catch (error) {}
}

export const getCurrentUser = () => {
  let user = null
  try {
    user =
      localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user"))
        : null
    if (user) {
      user.schools = user.schools ? JSON.parse(user.schools) : []
      user.skills = user.skills ? JSON.parse(user.skills) : []
      user.interests = user.interests ? JSON.parse(user.interests) : []
      user.pastProjects = user.pastProjects ? JSON.parse(user.pastProjects) : []
      user.work_phone = user.work_phone ? JSON.parse(user.work_phone)[0] : ""
      user.acclime_finder_identifier =
      user.acclime_finder_identifier === "Y" ? true : false
    }
    return user
  } catch (error) {
    localStorage.removeItem("user")
    localStorage.removeItem("employee")
    localStorage.removeItem("permissions")
    Cookies.remove("access_token")
    Cookies.remove("employee_access_token")
    localStorage.removeItem("session_index")
    localStorage.removeItem("name_id")
    localStorage.removeItem("last_visited_uris")
    localStorage.removeItem("fd")
    localStorage.removeItem("redirectUrl")
    localStorage.removeItem("redirectUrlConsent")
    localStorage.removeItem(LSUriUser)
    localStorage.removeItem(LSUriClient)
    sessionStorage.removeItem(LSUriUser)
    sessionStorage.removeItem(LSUriClient)
    document.cookie = "idleTime=;expires=" + new Date(0).toUTCString()
    return null
  }
}

export const getCurrentEmployee = () => {
  let employee = null
  try {
    employee =
      localStorage.getItem("employee") != null
      ? JSON.parse(localStorage.getItem("employee"))
      : null
  } catch (error) {
    employee = null
  }
  return employee
}

export const getCurrentUserTeams = () => {
  let teams = []
  try {
    const user = getCurrentUser()
    teams = user && user.teams ? user.teams : []
  } catch (error) {
    teams = []
  }
  return teams
}

export const getCurrentUserCountryIds = () => {
  return getCurrentUserTeams().map((x) => x.country_id)
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user))
    } else {
      localStorage.removeItem("user")
    }
  } catch (error) {}
}

export const setCurrentEmployee = (employee) => {
  try {
    if (employee) {
      localStorage.setItem("employee", JSON.stringify(employee))
    } else {
      localStorage.removeItem("employee")
    }
  } catch (error) {}
}

export const updateCurrentUser = async (newObj) => {
  console.log(newObj)
  const user = Object.assign({}, getCurrentUser(), newObj)
  console.log(user)
  setCurrentUser(user)
}

export const isUserPortal = () => {
  let user = getCurrentUser()
  return user && user.isUserPortal ? true : false
}

export const isClientPortal = () => {
  let user = getCurrentUser()
  return user && user.isClientPortal ? true : false
}

export const isExternalPortal = () => {
  let user = getCurrentUser()
  return user && user.isExternalPortal ? true : false
}

export const getCurrentUserPermissions = () => {
  const guardNames = []
  if (isUserPortal()) {
    guardNames.push("api")
  } else if (isClientPortal()) {
    guardNames.push("client")
    guardNames.push("employee")
  } else {
    guardNames.push("external")
  }

  const user = getCurrentUser()
  let permissions = []
  if (user && user.roles) {
    user.roles.forEach(
      (x) =>
        guardNames.includes(x.guard_name) &&
        (permissions = permissions.concat(x.permissions)),
    )
  }
  const employee = getCurrentEmployee()
  if (employee && employee.roles) {
    employee.roles.forEach(
      (x) =>
        guardNames.includes(x.guard_name) &&
        (permissions = permissions.concat(x.permissions)),
    )
  }
  return permissions
}

export const getCurrentUserPermissionNames = () => {
  let permissions = getCurrentUserPermissions()
  let permissionNames = []
  permissionNames = permissions
    .map((x) => x.name && x.name.toLowerCase())
    .filter((value, index, self) => self.indexOf(value) === index)
  return permissionNames
}

export function hasPermission(permissions, isOrCase = 0) {
  permissions =
    permissions && permissions.length
      ? permissions.map((x) => x.toLowerCase())
      : []
  var flag = false
  var userPermissions = getCurrentUserPermissionNames()
  var countMatch = 0
  userPermissions.forEach(function(item) {
    if (permissions.indexOf(item) !== -1) {
      countMatch++
    }
  })
  if (isOrCase == 0) {
    //and
    if (countMatch == permissions.length) {
      flag = true
    }
  } else {
    //or
    if (countMatch > 0) {
      flag = true
    }
  }
  return flag
}

export const getCurrentDocItems = () => {
  let items = []
  try {
    if (localStorage.getItem("docItems")) {
      items = JSON.parse(localStorage.getItem("docItems"))
    }
  } catch (error) {}
  return items
}

export const setCurrentDocItems = (items) => {
  try {
    localStorage.setItem("docItems", JSON.stringify(items))
  } catch (error) {}
}

export const getCurrentInvoiceItems = () => {
  let items = []
  try {
    if (localStorage.getItem("invoiceItems")) {
      items = JSON.parse(localStorage.getItem("invoiceItems"))
    }
  } catch (error) {}
  return items
}

export const setCurrentInvoiceItems = (items) => {
  try {
    localStorage.setItem("invoiceItems", JSON.stringify(items))
  } catch (error) {}
}

export const getCurrentAcclimeSignDocItems = () => {
  let items = []
  try {
    if (localStorage.getItem("acclimeSignDocItems")) {
      items = JSON.parse(localStorage.getItem("acclimeSignDocItems"))
    }
  } catch (error) {}
  return items
}

export const setCurrentAcclimeSignDocItems = (items) => {
  try {
    localStorage.setItem("acclimeSignDocItems", JSON.stringify(items))
  } catch (error) {}
}

export const getADProfile = async (msalToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${msalToken.accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  }
  const payload = {
    requests: [
      {
        url: "/me",
        method: "GET",
        id: "me",
      },
      {
        url: "/me/photo/$value",
        method: "GET",
        id: "photo",
      },
      {
        url: "/me/memberOf",
        method: "GET",
        id: "group",
      },
    ],
  }
  const msAxios = axios.create()
  const res = await msAxios.post(
    "https://graph.microsoft.com/v1.0/$batch",
    payload,
    config,
  )
  const msResult = res.data.responses
  const meObj =
    msResult && msResult.length
      ? msResult.filter((x) => x.id === "me")[0]
      : null
  const me = meObj && meObj.hasOwnProperty("body") ? meObj.body : null
  const photoObj =
    msResult && msResult.length
      ? msResult.filter((x) => x.id === "photo")[0]
      : null
  const photo =
    photoObj && photoObj.hasOwnProperty("body") ? photoObj.body : null
  const memberOfObj =
    msResult && msResult.length
      ? msResult.filter((x) => x.id === "group")[0]
      : null
  const memberOf =
    memberOfObj && memberOfObj.hasOwnProperty("body")
      ? memberOfObj.body.hasOwnProperty("value")
        ? memberOfObj.body.value
        : []
      : []
  const adGroupIds = memberOf
    .filter((x) => x["@odata.type"] === "#microsoft.graph.group")
    .map((y) => y.id)
  const user = {
    me: me,
    photo: photo ? "data:image/png;base64," + photo : null,
    adGroupIds: adGroupIds,
  }
  return user
}

export const getLast12Days = () => {
  let items = []
  for (var i = 0; i < 12; i++) {
    items.push(
      moment()
        .subtract(i, "days")
        .format("D MMM"),
    )
  }
  return items.reverse()
}

export const getLast12Weeks = () => {
  let items = []
  for (var i = 0; i < 12; i++) {
    items.push(
      "Wk " +
        moment()
          .subtract(i, "week")
          .format("W"),
    )
  }
  return items.reverse()
}

export const getLast12Months = () => {
  let items = []
  for (var i = 0; i < 12; i++) {
    items.push(
      moment()
        .subtract(i, "months")
        .format("MMM"),
    )
  }
  return items.reverse()
}

export const getLast12Years = () => {
  let items = []
  for (var i = 0; i < 12; i++) {
    items.push(
      moment()
        .subtract(i, "years")
        .format("Y"),
    )
  }
  return items.reverse()
}

export const getRoundedZeros = (value, up = true) => {
  var roundto = "1"

  for (let i = 1; i < parseInt(value).toString().length; i++) {
    roundto = roundto.concat("0")
  }

  roundto = parseInt(roundto)

  if (up === true) {
    return Math.ceil(value / roundto) * roundto
  } else {
    return Math.floor(value / roundto) * roundto
  }
}

export const getRoundedTo = (value) => {
  var roundto = "1"

  for (let i = 1; i < parseInt(value).toString().length; i++) {
    roundto = roundto.concat("0")
  }
  return parseInt(roundto)
}

export const getReadableNumber = (number, decimal) => {
  // number, decimal
  if (number >= 1000) {
    let x = ("" + number).length
    let p = Math.pow
    let d = p(10, decimal)
    x -= x % 3
    return Math.round((number * d) / p(10, x)) / d + " KMBTPE"[x / 3]
  } else {
    return number
  }
}

export const isHostnameInRedirectWhitelist = (url) => {
  const whitelist = appConfig.redirect_urls // array
  const redirectUrlObj = new URL(url)
  const redirectUrlHostname = redirectUrlObj.hostname
  const whitelistHostname = whitelist.map((whiteUrl) => {
    const whiteUrlObj = new URL(whiteUrl)
    return whiteUrlObj.hostname
  })
  console.log("whitelist: ", whitelist)
  console.log("redirectUrlHostname: ", redirectUrlHostname)
  return whitelistHostname.includes(redirectUrlHostname) ? true : false
}

export const hasRedirectUrlforUserPortal = () => {
  const redirectUrl = localStorage.getItem("redirectUrl")
  return redirectUrl && redirectUrl.includes(location.origin+adminRoot) ? true : false;
}

export const getRedirectUrlforUserPortal = () => {
  const redirectUrl = localStorage.getItem("redirectUrl")
  localStorage.removeItem("redirectUrl");
  return redirectUrl && redirectUrl.includes(location.origin+adminRoot)? redirectUrl : null;
}

export const getRedirectUrlforClientPortal = () => {
  const redirectUrl = localStorage.getItem("redirectUrl")
  localStorage.removeItem("redirectUrl");
  return redirectUrl && redirectUrl.includes(location.origin+clientRoot) ? redirectUrl : null;
}

export const getRedirectUrlforExternalPortal = () => {
  const redirectUrl = localStorage.getItem("redirectUrl")
  localStorage.removeItem("redirectUrl");
  return redirectUrl && redirectUrl.includes(location.origin+externalRoot) ? redirectUrl : null;
}