<template>
  <div class="page-header-box">
    <b-row>
      <b-colxx>
        <div class="float-none clearfix">
          <piaf-breadcrumb :itemsProp="breadcrumbItems" />
        </div>
        <div class="d-block d-md-inline-block float-md-left" v-html="pageTitle"></div>
        <div class="d-block d-md-inline-block pt-1 float-md-right">
          <div class="search-sm d-inline-block mr-1 align-top max-w-100 width-400">
            <b-input :placeholder="$t('menu.search')" @change="onSearch" v-model="serverParams.filter.universal" style="border-radius: none;" />
          </div>
        </div>
      </b-colxx>
    </b-row>
    <b-row class="mt-2">
      <b-colxx class="mt-2">
        <sub-menu :menuParentId="menuParentId" :hasMyClient="hasMyClient" />
        <div class="float-right ml-2" style="cursor: pointer">
          <b-overlay :show="busyFilter" spinner-small rounded opacity="0.6" spinner spinner-variant="primary" class="d-inline-block">
            <b-link :disabled="busyFilter" class="text-upper text-bold" @click="$emit('update:isRightPanelOpen', !isRightPanelOpen)"><i class="simple-icon-equalizer"></i>
              <div class="float-right ml-1">{{$tc('button.filter')}}</div>
            </b-link>
          </b-overlay>
        </div>
      </b-colxx>
    </b-row>
    <b-row>
      <b-colxx>
        <div class="w-100">
        </div>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
  // import {
  //   appConfig
  // } from "@/constants/config";
  //import * as Utils from '@/utils/utils';

  export default {
    name: "PageHeader",
    components: {},
    props: [
      'dataItem',
      'breadcrumbItems',
      'menuParentId',
      'busy',
      'pageTitle',
      'serverParams',
      'isRightPanelOpen',
      'hasMyClient',
      'busyFilter',
      'selectedFilters'
    ],
    data() {
      return {
        // appConfig: appConfig,
        // Utils: Utils,
        // fd: Utils.fd(),
      }
    },
    mounted() {},
    methods: {
      onSearch() {
        this.$emit('onSearch');
      },
    },
    computed: {}
  }
</script>