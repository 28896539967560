<template>
  <div @click.stop="()=>{}">
    <div v-for="(item, index) in filteredMenuItems(menuItems)"
      :class="{'list-unstyled':true, 'd-block' : (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id }"
      :data-parent="item.id" :key="`sub_${item.id}`">
      <div class="float-left sep-step" v-for="(sub,subIndex) in filteredMenuItems(item.submenus)"
        :key="`sub_${subIndex}`"
        :class="{'step-right' : subIndex+1 !== filteredMenuItems(item.submenus).length, 'has-sub-item' : sub.subs && sub.subs.length > 0 , 'active' : $route.path.indexOf(sub.to)>-1}">
        <b-link class="submenu text-upper text-bold disabled-link" :to="sub.to.replace(':id', $route.params.id)" disabled>
          <div class="float-right ml-1" :class="{'active' : $route.path.indexOf(sub.to)>-1}">{{subIndex+1}}. {{ $t(sub.label) }}</div>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
  } from "vuex";
  import menu from "../../constants/menu";
  import {
    isClientPortal,
    isUserPortal,
    hasPermission
  } from "../../utils";

  export default {
    props: ['menuParentId', 'componentName', 'hasMyClient', 'missingData'],
    data() {
      return {
        selectedParentMenu: "",
        viewingParentMenu: "",
      };
    },
    mounted() {
    },
    beforeDestroy() {
    },

    methods: {
      //For UserRole
      filteredMenuItems(menuItems) {
        if (!this.hasMyClient) {
          menuItems = menuItems.filter(x => x.label !== 'menu.my-client')
        }
        return menuItems ?
          menuItems.filter(
            (x) => hasPermission(x.permission, x.anyPermission)
          ) : [];
      },
    },

    computed: {
      ...mapGetters({
        isClientPortal: "auth/isClientPortal", // <= not reactive
      }),
      menuItems() {
        return isUserPortal() ? menu.userMenu.filter(x => x
          .id === this.menuParentId) : isClientPortal() ? menu.clientMenu.filter(x => x.id === this.menuParentId) : menu.externalMenu.filter(x => x.id === this.menuParentId)
      },
    },
    watch: {
    },
  };
</script>

<style nonce="kbuIoOUjR2s3txQqq71n" lang="scss">
  .box-overlay {
    position: absolute;
    top: 1px;
    left: -1px;
    margin-top: 0 !important;
    margin-left: 0 !important;
    &:before { width: 12px; height: 12px; }
  }
  .hide-menu-icon {
    &:before { color: transparent; }
  }
</style>