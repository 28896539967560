"use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.MsalPlugin2 = exports.MsalPlugin = exports.msalPluginInstance2 = exports.msalPluginInstance = void 0;
var msal = require("@azure/msal-browser");
var vue_1 = require("vue");
var msalInstance;
var msalInstance2;

var b2c_scope = [
  "openid",
  "profile",
  "offline_access",
  "https://AgilityTest01.onmicrosoft.com/api/read",
];

var MsalPlugin = /** @class */ (function() {
  function MsalPlugin() {
    this.pluginOptions = {
      clientId: "",
      loginAuthority: "",
      passwordAuthority: "",
      knownAuthority: "",
      redirectUri: "",
      postLogoutRedirectUri: "",
    };
    this.isAuthenticated = false;
    this.accounts = [];
  }
  MsalPlugin.prototype.install = function(vue, options) {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified");
    }
    this.pluginOptions = options;
    this.initialize(options);
    exports.msalPluginInstance = this;
    vue.prototype.$msal = vue_1.default.observable(exports.msalPluginInstance);
    window.msal = vue_1.default.observable(exports.msalPluginInstance);
  };
  MsalPlugin.prototype.initialize = function(options) {
    var msalConfig = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority],
      },
      cache: {
        cacheLocation: "localStorage",
      },
      system: {
        loggerOptions: {
          loggerCallback: function(level, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                return;
              case msal.LogLevel.Info:
                return;
              case msal.LogLevel.Verbose:
                return;
              case msal.LogLevel.Warning:
                return;
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose,
        },
      },
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);
    this.isAuthenticated = this.getIsAuthenticated();
  };
  MsalPlugin.prototype.signIn = async function(callback) {
    try {
      const loginRequest = {
        scopes: ["user.read"],
      };
      localStorage.setItem("logging_in", "true");
      const loginResponse = msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      location.reload();
    }
  };
  MsalPlugin.prototype.handleRedirectPromise = async function(loginFn) {
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse) {
          this.isAuthenticated = !!tokenResponse.account;
          loginFn();
        }
      })
      .catch((err) => {
        if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
          msalInstance.loginRedirect({
            scopes: ["user.read"],
            authority: this.pluginOptions.passwordAuthority,
          });
        }
      });
  };
  MsalPlugin.prototype.signOut = async function() {
    await msalInstance.logoutRedirect();
    this.isAuthenticated = false;
  };
  MsalPlugin.prototype.acquireToken = async function() {
    const request = {
      account: msalInstance.getAllAccounts()[0],
      scopes: ["user.read"],
    };
    try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return msalInstance
          .acquireTokenRedirect(request)
          .catch((popupError) => {
            console.error(popupError);
          });
      }
      return false;
    }
  };
  MsalPlugin.prototype.getIsAuthenticated = function() {
    var accounts = msalInstance.getAllAccounts();
    const accountFiltered = accounts.filter(
      (x) => x.environment === "login.microsoftonline.com"
    );
    this.accounts = accountFiltered;
    return accountFiltered && accountFiltered.length > 0;
  };
  return MsalPlugin;
})();

exports.MsalPlugin = MsalPlugin;

var MsalPlugin2 = /** @class */ (function() {
  function MsalPlugin2() {
    this.pluginOptions = {
      clientId: "",
      loginAuthority: "",
      passwordAuthority: "",
      knownAuthority: "",
      redirectUri: "",
      postLogoutRedirectUri: "",
    };
    this.isAuthenticated = false;
    this.accounts = [];
  }
  MsalPlugin2.prototype.install = function(vue, options) {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified");
    }
    this.pluginOptions = options;
    this.initialize(options);
    exports.msalPluginInstance2 = this;
    vue.prototype.$msal2 = vue_1.default.observable(
      exports.msalPluginInstance2
    );
    window.msal2 = vue_1.default.observable(exports.msalPluginInstance2);
  };
  MsalPlugin2.prototype.initialize = function(options) {
    var msalConfig = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority],
      },
      cache: {
        cacheLocation: "localStorage",
      },
      system: {
        loggerOptions: {
          loggerCallback: function(level, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                return;
              case msal.LogLevel.Info:
                return;
              case msal.LogLevel.Verbose:
                return;
              case msal.LogLevel.Warning:
                return;
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose,
        },
      },
    };
    msalInstance2 = new msal.PublicClientApplication(msalConfig);
    this.isAuthenticated = this.getIsAuthenticated();
  };
  MsalPlugin2.prototype.signIn = async function(callback) {
    try {
      const loginRequest = {
        scopes: b2c_scope,
      };
      localStorage.setItem("logging_in", "true")
      const loginResponse = await msalInstance2.loginRedirect(loginRequest);
    } catch (err) {
      // handle error
      if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
          const passwordResetResponse = await msalInstance2.loginRedirect({
            scopes: b2c_scope,
            authority: this.pluginOptions.passwordAuthority,
          });
        } catch (passwordResetError) {
          console.error(passwordResetError);
        }
      } else {
        location.reload();
      }
    }
  };
  MsalPlugin2.prototype.handleRedirectPromise = async function(loginFn) {
    msalInstance2
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse) {
          this.isAuthenticated = !!tokenResponse.account;
          loginFn();
        }
      })
      .catch((err) => {
        if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
          msalInstance2.loginRedirect({
            scopes: b2c_scope,
            authority: this.pluginOptions.passwordAuthority,
          });
        }
      });
  };
  MsalPlugin2.prototype.signOut = async function() {
    await msalInstance2.logoutRedirect();
    this.isAuthenticated = false;
  };
  MsalPlugin2.prototype.acquireToken = async function() {
    const request = {
      account: msalInstance2.getAllAccounts()[0],
      scopes: b2c_scope,
    };
    try {
      const response = await msalInstance2.acquireTokenSilent(request);
      return response;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return msalInstance2
          .acquireTokenRedirect(request)
          .catch((popupError) => {
            console.error(popupError);
          });
      }
      return false;
    }
  };
  MsalPlugin2.prototype.getIsAuthenticated = function() {
    var accounts = msalInstance2.getAllAccounts();
    const accountFiltered = accounts.filter(
      (x) => x.environment === "login.acclime.com"
    );
    this.accounts = accountFiltered;
    return accountFiltered && accountFiltered.length > 0;
  };
  return MsalPlugin2;
})();

exports.MsalPlugin2 = MsalPlugin2;
