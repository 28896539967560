import { adminRoot, clientRoot, externalRoot, appConfig } from "./config";

const externalMenu = [
  {
    id: "sign-document",
    icon: "simple-icon-sign",
    label: "menu.acclime-sign",
    to: `${externalRoot}/acclime-sign/document-to-sign`,
  },
];

const clientMenu = [
  {
    id: "home",
    icon: "simple-icon-home",
    label: "menu.dashboard",
    to: `${clientRoot}/home`,
    permission: ["view dashboard"],
  },
  {
    id: "your-documents",
    icon: "simple-icon-docs",
    label: "menu.your-documents",
    to: `${clientRoot}/your-documents/list`,
    permission: ["view document"],
  },
  {
    id: "send-document",
    icon: "simple-icon-cloud-upload",
    label: "menu.send",
    to: `${clientRoot}/send/document`,
    permission: ["view document"],
    anyPermission: true,
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.send-document",
        to: `${clientRoot}/send/document`,
        permission: ["upload document"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.send-information",
        to: `${clientRoot}/send/information`,
        permission: ["upload information"],
      },
    ],
  },
  {
    id: "shared-documents",
    icon: "simple-icon-docs",
    label: "menu.shared-documents",
    to: `${clientRoot}/shared-documents/list`,
    permission: ["view shared document"],
  },
  {
    id: "contact",
    icon: "simple-icon-speech",
    label: "menu.contact-acclime",
    to: `${clientRoot}/contact`,
    permission: ["view contact acclime"],
  },
  {
    id: "user-access",
    icon: "simple-icon-people",
    label: "menu.user-access",
    to: `${clientRoot}/user-access/list`,
    permission: ["view client contact"],
    submenus: [
      {
        label: "menu.user-access",
        to: `${clientRoot}/user-access/list`,
        permission: ["view client contact"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.user-access-create",
        to: `${clientRoot}/user-access/create`,
        permission: ["view client contact"],
      },
    ],
  },
  {
    id: "invoice",
    icon: "simple-icon-docs",
    label: "menu.invoice",
    to: `${clientRoot}/invoice/list`,
    permission: ["view invoice"],
  },
  // {
  //   id: "board-management",
  //   newWindow: true,
  //   icon: "simple-icon-board-management",
  //   label: "menu.board-management",
  //   to: appConfig.endpoint.boardManagement + '?source=adb2c',
  //   permission: ['view board management'],
  //   anyPermission: true,
  // },
  {
    id: "sign-document",
    icon: "simple-icon-sign",
    label: "menu.acclime-sign",
    to: `${clientRoot}/acclime-sign/document-to-sign`,
    permission: ["view acclime sign"],
    anyPermission: true,
  },
  {
    id: "payroll",
    icon: "simple-icon-wallet",
    label: "menu.payroll",
    to: `${clientRoot}/payroll`,
    permission: ["view payroll"],
    anyPermission: true,
    submenus: [
      {
        icon: "simple-icon-report",
        label: "menu.payroll-monthly-report",
        to: `${clientRoot}/payroll/monthly-report`,
        permission: ["view payroll"],
      },
      {
        icon: "simple-icon-user",
        label: "menu.payroll-team",
        to: `${clientRoot}/payroll/team`,
        permission: ["view payroll"],
      },
    ],
  },
  {
    id: "my-home",
    icon: "simple-icon-home",
    label: "menu.my-home",
    to: `${clientRoot}/my-home`,
    permission: ["view my home"],
    anyPermission: true,
  },
  {
    id: "my-hr-document",
    icon: "simple-icon-docs",
    label: "menu.my-hr-document",
    to: `${clientRoot}/my-hr-documents`,
    permission: ["view my hr document"],
    anyPermission: true,
  },
  {
    id: "my-payslip",
    icon: "simple-icon-docs",
    label: "menu.my-payslip",
    to: `${clientRoot}/my-payslips`,
    permission: ["view my payslip"],
    anyPermission: true,
  },
  // {
  //   id: "my-payroll",
  //   icon: "simple-icon-wallet",
  //   label: "menu.my-payroll",
  //   to: `${clientRoot}/my-payroll`,
  //   permission: ['view my payroll'],
  //   anyPermission: true,
  //   submenus: [{
  //       icon: "simple-icon-docs",
  //       label: "menu.my-payroll",
  //       to: `${clientRoot}/my-payroll/overview`,
  //       permission: ['view my payroll'],
  //     },
  //     {
  //       icon: "simple-icon-docs",
  //       label: "menu.my-payroll-payslip",
  //       to: `${clientRoot}/my-payroll/payslips`,
  //       permission: ['view payslip my payroll'],
  //     },
  //     {
  //       icon: "simple-icon-docs",
  //       label: "menu.my-payroll-document",
  //       to: `${clientRoot}/my-payroll/documents`,
  //       permission: ['view document my payroll'],
  //     },
  //   ]
  // },
];

const userMenu = [
  {
    id: "dashboard",
    icon: "simple-icon-home",
    label: "menu.dashboard",
    to: `${adminRoot}/dashboard`,
    permission: [],
    submenus: [
      {
        label: "menu.my-dashboard",
        to: `${adminRoot}/dashboard/my-dashboard`,
        permission: ["view client"],
      },
      {
        label: "menu.employee-directory",
        to: `${adminRoot}/dashboard/employee-directory`,
        permission: ["view emplyeee directory dashboard"],
      },
      {
        label: "menu.acclime-finder",
        to: `${adminRoot}/dashboard/acclime-finder`,
        permission: ["view acclime finder dashboard"],
      },
    ],
  },
  // {
  //   groupTitle: "menu.client-manager",
  //   id: "client",
  //   icon: "simple-icon-people",
  //   label: "menu.client",
  //   to: `${adminRoot}/clients/active`,
  //   permission: ['view client'],
  //   submenus: [{
  //     icon: "simple-icon-star",
  //     label: "menu.my-client",
  //     to: `${adminRoot}/clients/my`,
  //     permission: ['view client'],
  //   }, {
  //     icon: "simple-icon-check",
  //     label: "menu.active-client",
  //     to: `${adminRoot}/clients/active`,
  //     permission: ['view client'],
  //   },
  //   {
  //     icon: "simple-icon-clock",
  //     label: "menu.on-hold-client",
  //     to: `${adminRoot}/clients/on-hold`,
  //     permission: ['view client'],
  //   },
  //   {
  //     icon: "simple-icon-clock",
  //     label: "menu.onboarding-client",
  //     to: `${adminRoot}/clients/onboarding`,
  //     permission: ['view client'],
  //   },
  //   {
  //     icon: "simple-icon-close",
  //     label: "menu.lapsed-client",
  //     to: `${adminRoot}/clients/lapsed`,
  //     permission: ['view client'],
  //   },
  //   ]
  // },
  {
    groupTitle: "menu.client-manager",
    id: "client",
    icon: "simple-icon-people",
    label: "menu.client",
    to: `${adminRoot}/clients`,
    permission: ["view client"],
    submenus: [
      {
        icon: "simple-icon-people",
        label: "menu.all-clients",
        to: `${adminRoot}/clients`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-file-text",
        label: "menu.invoices-profile",
        to: `${adminRoot}/clients/invoice`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-check-circle",
        label: "menu.deals-profile",
        to: `${adminRoot}/clients/deal`,
        permission: ["view client"],
      },
    ],
  },
  {
    groupTitle: "menu.client-manager",
    id: "add-client",
    icon: "simple-icon-user-follow",
    label: "menu.new-client",
    to: `${adminRoot}/new-client/client-corporate`,
    permission: ["create client", "create ubo"],
    anyPermission: true,
    submenus: [
      // {
      //   icon: "simple-icon-plus",
      //   label: "menu.add-hubspot-client",
      //   to: `${adminRoot}/new-client/client-hubspot`,
      //   permission: ['create client'],
      // },
      {
        icon: "simple-icon-plus",
        label: "menu.add-corporate-client",
        to: `${adminRoot}/new-client/client-corporate`,
        permission: ["create client"],
      },
      // {
      //   icon: "simple-icon-plus",
      //   label: "menu.add-individual-client",
      //   to: `${adminRoot}/new-client/client-individual`,
      //   permission: ['create client'],
      // },
    ],
  },
  {
    groupTitle: "menu.client-manager",
    id: "parent-group",
    icon: "simple-icon-user-follow",
    label: "menu.client-shareholder",
    to: `${adminRoot}/parent-groups/list`,
    permission: ["view ubo"],
    anyPermission: true,
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/parent-groups/list`,
        permission: ["view ubo"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.shareholder-create-corporate",
        to: `${adminRoot}/parent-groups/create-corporate`,
        permission: ["create ubo"],
      },
      // {
      //   icon: "simple-icon-plus",
      //   label: "menu.shareholder-create-individual",
      //   to: `${adminRoot}/parent-groups/create-individual`,
      //   permission: ["create ubo"],
      // },
    ],
  },
  // {
  //   groupTitle: "menu.client-manager",
  //   id: "onboarding-dashboard",
  //   icon: "simple-icon-list",
  //   label: "onboarding dashboard",
  //   to: `${adminRoot}/onboarding-dashboard/list`,
  //   permission: ['view onboarding_dashboard'],
  //   anyPermission: true,
  //   submenus: [{
  //     icon: "",
  //     label: "menu.my-client",
  //     to: `${adminRoot}/onboarding-dashboard/list/my-clients`,
  //     permission: ['view onboarding_dashboard'],
  //   },
  //   {
  //     icon: "",
  //     label: "menu.my-team",
  //     to: `${adminRoot}/onboarding-dashboard/list/my-team`,
  //     permission: ['view onboarding_dashboard'],
  //   }
  //   ]
  // },
  {
    id: "onboarding-dashboard-ready-initiation",
    submenus: [
      {
        icon: "",
        label: "menu.my-client",
        to: `${adminRoot}/onboarding-dashboard/ready-initiation/my-clients`,
        permission: ["view onboarding_dashboard"],
      },
      {
        icon: "",
        label: "menu.my-team",
        to: `${adminRoot}/onboarding-dashboard/ready-initiation/my-team`,
        permission: ["view onboarding_dashboard"],
      },
    ],
  },
  {
    id: "onboarding-dashboard-waiting-onboarding",
    submenus: [
      {
        icon: "",
        label: "menu.my-client",
        to: `${adminRoot}/onboarding-dashboard/waiting-onboarding/my-clients`,
        permission: ["view onboarding_dashboard"],
      },
      {
        icon: "",
        label: "menu.my-team",
        to: `${adminRoot}/onboarding-dashboard/waiting-onboarding/my-team`,
        permission: ["view onboarding_dashboard"],
      },
    ],
  },
  {
    id: "onboarding-dashboard-waiting-review",
    submenus: [
      {
        icon: "",
        label: "menu.my-client",
        to: `${adminRoot}/onboarding-dashboard/waiting-review/my-clients`,
        permission: ["view onboarding_dashboard"],
      },
      {
        icon: "",
        label: "menu.my-team",
        to: `${adminRoot}/onboarding-dashboard/waiting-review/my-team`,
        permission: ["view onboarding_dashboard"],
      },
    ],
  },
  {
    id: "onboarding-dashboard-latest-onboarded",
    submenus: [
      {
        icon: "",
        label: "menu.my-client",
        to: `${adminRoot}/onboarding-dashboard/latest-onboarded/my-clients`,
        permission: ["view onboarding_dashboard"],
      },
      {
        icon: "",
        label: "menu.my-team",
        to: `${adminRoot}/onboarding-dashboard/latest-onboarded/my-team`,
        permission: ["view onboarding_dashboard"],
      },
    ],
  },
  {
    groupTitle: "menu.client-manager",
    id: "conflict-search",
    icon: "simple-icon-energy",
    label: "menu.conflict-search",
    to: `${adminRoot}/conflict-search/list`,
    permission: ["view conflict search"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "board-management",
    newWindow: true,
    icon: "simple-icon-acclime-works",
    label: "menu.acclime-works",
    to: "https://acclimecom.sharepoint.com/sites/AcclimeWorks",
    permission: ["view dashboard"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "board-management",
    newWindow: true,
    icon: "simple-icon-board-management",
    label: "menu.board-management",
    to: appConfig.endpoint.boardManagement + "?source=ad",
    permission: ["view board management"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "acclime-sign",
    icon: "simple-icon-sign",
    label: "menu.acclime-sign",
    to: `${adminRoot}/acclime-sign`,
    permission: ["view acclime sign"],
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/acclime-sign/list`,
        permission: ["view acclime sign"],
      },
    ],
  },
  {
    groupTitle: "menu.apps",
    id: "it-service-desk",
    newWindow: true,
    icon: "simple-icon-service-desk",
    label: "menu.it-service-desk",
    to: appConfig.endpoint.itServiceDesk,
    permission: ["view it service desk"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "acclime-academy",
    newWindow: true,
    icon: "simple-icon-acclime-academy",
    label: "menu.acclime-academy",
    to: appConfig.endpoint.acclimeAcademy,
    permission: ["view acclime academy"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "acclime-payroll",
    newWindow: true,
    icon: "simple-icon-sharepoint",
    label: "menu.acclime-payroll",
    to: appConfig.endpoint.acclime_payroll,
    permission: ["view acclime payroll"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "book-a-desk",
    newWindow: true,
    icon: "simple-icon-book-a-desk",
    label: "menu.book-a-desk",
    to: appConfig.endpoint.book_a_desk,
    permission: ["view book a desk"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "business-hub",
    newWindow: true,
    icon: "simple-icon-business-hub",
    label: "menu.business-hub",
    to: appConfig.endpoint.business_hub,
    permission: ["view business hub"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.apps",
    id: "it-hub",
    newWindow: true,
    icon: "simple-icon-it-hub",
    label: "menu.it-hub",
    to: appConfig.endpoint.it_hub,
    permission: ["view it hub"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.report",
    id: "analytic",
    icon: "simple-icon-graph",
    label: "menu.analytics",
    to: `${adminRoot}/analytics`,
    permission: [
      "view client analytics",
      "view user analytics",
      "view data quality analytics",
    ],
    anyPermission: true,
    submenus: [
      {
        icon: "simple-icon-chart",
        label: "menu.client-analytics",
        to: `${adminRoot}/analytics/client-analytics`,
        permission: ["view client analytics"],
      },
      {
        icon: "simple-icon-chart",
        label: "menu.user-analytics",
        to: `${adminRoot}/analytics/user-analytics`,
        permission: ["view user analytics"],
      },
      {
        icon: "simple-icon-chart",
        label: "menu.data-quality-analytics",
        to: `${adminRoot}/analytics/data-quality-analytics`,
        permission: ["view data quality analytics"],
      },
    ],
  },
  {
    groupTitle: "menu.report",
    id: "insytes",
    newWindow: true,
    icon: "simple-icon-graph",
    label: "menu.insytes",
    to: appConfig.endpoint.insytes,
    permission: ["view insytes"],
    anyPermission: true,
  },
  {
    groupTitle: "menu.report",
    id: "client-report",
    icon: "simple-icon-people",
    label: "menu.client-report",
    to: `${adminRoot}/client-report`,
    permission: ["view client report"],
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/client-report`,
        permission: ["view client report"],
      },
    ],
  },
  {
    groupTitle: "menu.report",
    id: "user-activity-log",
    icon: "simple-icon-clock",
    label: "menu.activity-log",
    to: `${adminRoot}/log`,
    permission: ["view activity log"],
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/log`,
        permission: ["view activity log"],
      },
    ],
  },
  {
    id: "client-detail",
    submenus: [
      {
        icon: "simple-icon-organization",
        label: "menu.client-onboarding",
        to: `${adminRoot}/clients/:id/onboarding`,
        permission: ["view onboarding client"],
      },
      {
        icon: "simple-icon-info",
        label: "menu.client-info",
        to: `${adminRoot}/clients/:id/info`,
        permission: ["view information client"],
      },
      {
        icon: "simple-icon-notebook",
        label: "menu.client-contact",
        to: `${adminRoot}/clients/:id/contacts`,
        permission: ["view contacts client"],
      },
      {
        icon: "simple-icon-location-pin",
        label: "menu.client-address",
        to: `${adminRoot}/clients/:id/addresses`,
        permission: ["view addresses client"],
      },
      {
        icon: "simple-icon-badge",
        label: "menu.client-rm",
        to: `${adminRoot}/clients/:id/relationship-managers`,
        permission: ["view relationship managers client"],
      },
      {
        icon: "simple-icon-board-management",
        label: "menu.client-board-management",
        to: `${adminRoot}/clients/:id/board-management`,
        permission: ["view board management client"],
      },
      // {
      //   icon: "simple-icon-docs",
      //   label: "menu.client-invoice",
      //   to: `${adminRoot}/clients/:id/invoices`,
      //   permission: ['view invoices client'],
      // },
      {
        icon: "simple-icon-docs",
        label: "menu.client-invoice-new",
        to: `${adminRoot}/clients/:id/invoices`,
        permission: ["view invoices client"],
      },
      // {
      //   icon: "simple-icon-globe",
      //   label: "menu.client-bd",
      //   to: `${adminRoot}/clients/:id/bd`,
      //   permission: ['view bd client'],
      // },
      {
        icon: "simple-icon-globe",
        label: "menu.client-bd-nexus",
        to: `${adminRoot}/clients/:id/bd`,
        permission: ["view bd client"],
      },
      {
        icon: "simple-icon-layers",
        label: "menu.client-onboarding-status",
        to: `${adminRoot}/clients/:id/onboarding-status`,
        permission: ["view onboarding status client"],
      },
      {
        icon: "simple-icon-folder-alt",
        label: "menu.client-document",
        to: `${adminRoot}/clients/:id/documents`,
        permission: ["view documents client"],
      },
      // {
      //   icon: "simple-icon-pie-chart",
      //   label: "menu.client-shareholder",
      //   to: `${adminRoot}/clients/:id/shareholders`,
      //   permission: ['view parent groups client'],
      // },
      {
        icon: "simple-icon-bubble",
        label: "menu.client-comment",
        to: `${adminRoot}/clients/:id/comment`,
        permission: ["view comment client"],
      },
      {
        id: "menu-client-corporate-secretary",
        icon: "simple-icon-doc",
        label: "menu.client-corporate-secretary",
        to: `${adminRoot}/clients/:id/corporate-secretary`,
        permission: ["view corporate secretary client"],
      },
      {
        id: "menu-client-bank-details",
        icon: "",
        label: "menu.client-bank-detail",
        to: `${adminRoot}/clients/:id/bank-details`,
        permission: ["view bank details client"],
      },
      {
        id: "menu-client-approval-rights",
        icon: "",
        label: "menu.client-approval-rights",
        to: `${adminRoot}/clients/:id/approval-rights`,
        permission: ["view approval rights client"],
      },
      {
        icon: "simple-icon-wallet",
        label: "menu.client-payroll",
        to: `${adminRoot}/clients/:id/payroll`,
        permission: ["view payroll client"],
      },
      {
        icon: "simple-icon-share",
        label: "menu.client-shared-folder",
        to: `${adminRoot}/clients/:id/shared-folder`,
        permission: ["view shared folder client"],
      },
    ],
  },
  {
    id: "client-service",
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/clients/:id/info/services`,
        permission: ["view client"],
      },
    ],
  },
  {
    id: "client-contact",
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.contact-create",
        to: `${adminRoot}/clients/:id/contacts/create`,
        permission: ["view client"],
      },
    ],
  },
  {
    id: "client-address",
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.address-create",
        to: `${adminRoot}/clients/:id/addresses/create`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/clients/:id/addresses/list`,
        permission: ["view client"],
      },
    ],
  },
  {
    id: "client-rm",
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.rm-create",
        to: `${adminRoot}/clients/:id/relationship-managers/create`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/clients/:id/relationship-managers/list`,
        permission: ["view client"],
      },
    ],
  },
  {
    id: "client-shareholder",
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.shareholder-create-corporate",
        to: `${adminRoot}/clients/:id/shareholders/create-corporate`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.shareholder-create-individual",
        to: `${adminRoot}/clients/:id/shareholders/create-individual`,
        permission: ["view client"],
      },
      {
        icon: "simple-icon-menu",
        label: "menu.list",
        to: `${adminRoot}/clients/:id/shareholders/list`,
        permission: ["view client"],
      },
    ],
  },
  {
    id: "acclime-sign-list",
    submenus: [
      {
        icon: "simple-icon-menu",
        label: "menu.acclime-sign-dashboard",
        to: `${adminRoot}/acclime-sign/list`,
        permission: ["view acclime sign"],
      },
      // {
      //   icon: "simple-icon-sign",
      //   label: "menu.acclime-sign-document-to-sign",
      //   to: `${adminRoot}/acclime-sign/document-to-sign`,
      //   permission: ['create acclime sign'],
      // },
    ],
  },
  {
    id: "acclime-sign-create",
    submenus: [
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-select-document",
        to: `${adminRoot}/acclime-sign/create/select-document`,
        permission: ["create acclime sign"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-add-signer",
        to: `${adminRoot}/acclime-sign/create/add-signers`,
        permission: ["create acclime sign"],
      },
      // {
      //   icon: "simple-icon-plus",
      //   label: "menu.acclime-sign-create-add-observer",
      //   to: `${adminRoot}/acclime-sign/create/add-observers`,
      //   permission: ['create acclime sign'],
      // },
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-config-document",
        to: `${adminRoot}/acclime-sign/create/configure-document`,
        permission: ["create acclime sign"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-sign-document",
        to: `${adminRoot}/acclime-sign/create/sign-document`,
        permission: ["create acclime sign"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-send-for-signing",
        to: `${adminRoot}/acclime-sign/create/send-for-signing`,
        permission: ["create acclime sign"],
      },
      {
        icon: "simple-icon-plus",
        label: "menu.acclime-sign-create-confirmation",
        to: `${adminRoot}/acclime-sign/create/confirmation`,
        permission: ["create acclime sign"],
      },
    ],
  },
];

const data = {
  userMenu: userMenu,
  clientMenu: clientMenu,
  externalMenu: externalMenu,
};
export default data;
