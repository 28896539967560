import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from "./utils/auth.guard";
import {
  adminRoot,
  clientRoot,
  externalRoot
} from "./constants/config";
import {
  getCurrentUser,
} from '@/utils';
import { hasPermission } from "./utils";

Vue.use(VueRouter);
const user = getCurrentUser();
const routes = [
  {
    path: "/auth",
    component: () => import(/* auth */ "./views/auth"),
    redirect: "/auth/login",
    meta: { loginRequired: false },
    children: [
      {
        name: "login",
        path: "login",
        meta: { loginRequired: false },
        component: () => import(/* auth */ "./views/auth/Login"),
      },
      {
        name: "employee-login",
        path: "employee-login",
        meta: { loginRequired: false },
        component: () => import(/* auth */ "./views/auth/EmployeeLogin"),
      },
      {
        path: "external-login/:token",
        meta: { loginRequired: false },
        component: () => import(/* auth */ "./views/auth/ExternalLogin"),
      },
    ],
  },
  {
    path: "/employee-login",
    meta: { loginRequired: false },
    component: () => import(/* auth */ "./views/auth"),
    redirect: "/auth/employee-login",
  },
  {
    path: "/logout",
    meta: { loginRequired: false },
    component: () => import(/* auth */ "./views/logout"),
  },
  {
    path: "/",
    meta: { loginRequired: false },
  },
  {
    path: externalRoot,
    component: () => import(/* webpackChunkName: "app" */ "./views/external"),
    redirect: `${externalRoot}/home`,
    meta: {
      loginRequired: true,
      isExternalPortal: true,
    },
    children: [
      {
        path: "home",
        component: () =>
          import(
            /* webpackChunkName: "your-documents" */ "./views/external/home"
          ),
        name: "external-home",
        meta: {
          loginRequired: true,
          isExternalPortal: true,
        },
      },
      {
        path: "acclime-sign",
        component: () =>
          import(
            /* webpackChunkName: "external-acclime-sign" */ "./views/external/acclimesign"
          ),
        redirect: `${externalRoot}/acclime-sign/document-to-sign`,
        meta: {
          loginRequired: true,
          isExternalPortal: true,
        },
        children: [
          {
            path: "document-to-sign",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/external/acclimesign/AcclimeSignDocumentToSign"
              ),
            meta: {
              loginRequired: true,
              isExternalPortal: true,
            },
          },
          {
            path: "document-to-sign/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/external/acclimesign/SignDocument"
              ),
            meta: {
              loginRequired: true,
              isExternalPortal: true,
            },
          },
          {
            path: "document-to-sign/confirmation/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/external/acclimesign/SignedConfirmation"
              ),
            meta: {
              loginRequired: true,
              isExternalPortal: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/client/consent/:id",
    component: () =>
      import(/* webpackChunkName: "consent" */ "./views/Consent"),
    name: "consent",
    meta: {
      loginRequired: true,
      isClientPortal: true,
    },
  },
  {
    path: clientRoot,
    component: () => import(/* webpackChunkName: "app" */ "./views/client"),
    redirect:
      user && user.isContact ? `${clientRoot}/home` : `${clientRoot}/my-home`,
    meta: {
      loginRequired: true,
      isClientPortal: true,
    },
    children: [
      {
        path: "home",
        component: () =>
          import(/* webpackChunkName: "client-home" */ "./views/client/home"),
        name: "client-home",
        meta: {
          loginRequired: true,
          isClientPortal: true,
          permissions: ["view dashboard"],
        },
      },
      {
        path: "your-documents",
        component: () =>
          import(
            /* webpackChunkName: "your-documents" */ "./views/client/your-documents"
          ),
        redirect: `${clientRoot}/your-documents/list`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "your-documents" */ "./views/client/your-documents/YourDocumentIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view document"],
            },
          },
        ],
      },
      {
        path: "send",
        component: () =>
          import(
            /* webpackChunkName: "send-documents" */ "./views/client/send"
          ),
        redirect: `${clientRoot}/send/document`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "document",
            component: () =>
              import(
                /* webpackChunkName: "send-documents" */ "./views/client/send/SendDocumentIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["upload document"],
            },
          },
          {
            path: "information",
            component: () =>
              import(
                /* webpackChunkName: "send-info" */ "./views/client/send/SendInformationIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["upload information"],
            },
          },
        ],
      },
      {
        path: "shared-documents",
        component: () =>
          import(
            /* webpackChunkName: "shared-documents" */ "./views/client/shared-documents"
          ),
        redirect: `${clientRoot}/shared-documents/list`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "shared-documents" */ "./views/client/shared-documents/SharedDocumentIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view shared document"],
            },
          },
        ],
      },
      {
        path: "contact",
        component: () =>
          import(
            /* webpackChunkName: "client-contact" */ "./views/client/contact"
          ),
        meta: {
          loginRequired: true,
          isClientPortal: true,
          permissions: ["view contact acclime"],
        },
      },
      {
        path: "user-access",
        component: () =>
          import(
            /* webpackChunkName: "contact-access" */ "./views/client/user-access"
          ),
        redirect: `${clientRoot}/user-access/list`,
        meta: {
          loginRequired: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "contact-access" */ "./views/client/user-access/UserAccessIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view client contact"],
            },
          },
          {
            path: "create",
            component: () =>
              import(
                /* webpackChunkName: "contact-access" */ "./views/client/user-access/UserAccessDetail"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["create client contact"],
            },
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "contact-access" */ "./views/client/user-access/UserAccessDetail"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["edit client contact"],
            },
          },
        ],
      },
      {
        path: "invoice",
        component: () =>
          import(/* webpackChunkName: "invoice" */ "./views/client/invoice"),
        redirect: `${clientRoot}/invoice/list`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "invoice" */ "./views/client/invoice/InvoiceIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view invoice"],
            },
          },
        ],
      },
      {
        path: "news",
        component: () =>
          import(/* webpackChunkName: "news" */ "./views/client/News"),
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
      },
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "./views/client/profile/ProfileIndex"
          ),
        name: "client-profile",
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
      },
      {
        path: "acclime-sign",
        component: () =>
          import(
            /* webpackChunkName: "client-acclime-sign" */ "./views/client/acclimesign"
          ),
        redirect: `${clientRoot}/acclime-sign/document-to-sign`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "document-to-sign",
            component: () =>
              import(
                /* webpackChunkName: "client-acclime-sign" */ "./views/client/acclimesign/AcclimeSignDocumentToSign"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "document-to-sign/:id",
            component: () =>
              import(
                /* webpackChunkName: "client-acclime-sign" */ "./views/client/acclimesign/SignDocument"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "document-to-sign/confirmation/:id",
            component: () =>
              import(
                /* webpackChunkName: "client-acclime-sign" */ "./views/client/acclimesign/SignedConfirmation"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view acclime sign"],
            },
          },
        ],
      },
      {
        path: "payroll",
        component: () =>
          import(
            /* webpackChunkName: "client-payroll" */ "./views/client/payroll"
          ),
        redirect: `${clientRoot}/payroll/monthly-report`,
        meta: {
          loginRequired: true,
          isClientPortal: true,
        },
        children: [
          {
            path: "monthly-report",
            component: () =>
              import(
                /* webpackChunkName: "client-payroll-monthly-report" */ "./views/client/payroll/MonthlyReportIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view payroll"],
            },
          },
          {
            path: "team",
            component: () =>
              import(
                /* webpackChunkName: "client-payroll-team" */ "./views/client/payroll/TeamIndex"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view payroll"],
            },
          },
          {
            path: "team/employee/:id",
            component: () =>
              import(
                /* webpackChunkName: "client-payroll-team" */ "./views/client/payroll/employee"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view payroll"],
            },
          },
          {
            path: "team/employee/:id/payslips",
            component: () =>
              import(
                /* webpackChunkName: "client-payroll-team" */ "./views/client/payroll/payslip"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view payroll"],
            },
          },
          {
            path: "team/employee/:id/documents",
            component: () =>
              import(
                /* webpackChunkName: "client-payroll-team" */ "./views/client/payroll/document"
              ),
            meta: {
              loginRequired: true,
              isClientPortal: true,
              permissions: ["view payroll"],
            },
          },
        ],
      },
      {
        path: "my-home",
        component: () =>
          import(
            /* webpackChunkName: "client-my-home" */ "./views/client/employee"
          ),
        name: "client-my-home",
        meta: {
          loginRequired: true,
          isClientPortal: true,
          permissions: ["view my home"],
        },
      },
      {
        path: "my-payslips",
        component: () =>
          import(
            /* webpackChunkName: "client-my-payslip" */ "./views/client/employee/payslip"
          ),
        meta: {
          loginRequired: true,
          isClientPortal: true,
          permissions: ["view my payslip"],
        },
      },
      {
        path: "my-hr-documents",
        component: () =>
          import(
            /* webpackChunkName: "client-my-document" */ "./views/client/employee/document"
          ),
        meta: {
          loginRequired: true,
          isClientPortal: true,
          permissions: ["view my hr document"],
        },
      },
    ],
  },
  {
    path: adminRoot,
    component: () => import(/* webpackChunkName: "app" */ "./views/app"),
    redirect: `${adminRoot}/dashboard`,
    meta: {
      loginRequired: true,
      isUserPortal: true,
    },
    children: [
      {
        path: "dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "./views/app/dashboard"),
        redirect: `${adminRoot}/dashboard/my-dashboard`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "my-dashboard",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/MyDashboard"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "employee-directory",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/EmployeeDirectory"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "acclime-finder",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/AcclimeFinder"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "forms-documents",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/FormsDocuments"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "resources",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/Resources"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "training",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/dashboard/Training"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
        ],
      },

      {
        path: "new-client",
        component: () =>
          import(/* webpackChunkName: "new-client" */ "./views/app/client"),
        redirect: `${adminRoot}/new-client/client-hubspot`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "client-corporate",
            component: () =>
              import(
                /* webpackChunkName: "new-client" */ "./views/app/client/CorporateClientDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create client"],
            },
          },
          {
            path: "client-individual",
            component: () =>
              import(
                /* webpackChunkName: "new-client" */ "./views/app/client/IndividualClientDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create client"],
            },
          },
          {
            path: "client-hubspot",
            component: () =>
              import(
                /* webpackChunkName: "new-client" */ "./views/app/client/HubspotClientDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create client"],
            },
          },
        ],
      },
      {
        path: "clients",
        component: () =>
          import(/* webpackChunkName: "client" */ "./views/v2/app/client"),
        redirect: `${adminRoot}/clients`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "/",
            component: () =>
              import(
                /* webpackChunkName: "client" */ "./views/v2/app/client/ClientIndex"
              ),
            props: {
              businessStatusProp: ["ACTIVE"],
            },
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "invoice",
            component: () =>
              import(
                /* webpackChunkName: "client" */ "./views/v2/app/client/invoice/InvoiceIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: "deal",
            component: () =>
              import(
                /* webpackChunkName: "client" */ "./views/v2/app/client/deal/DealIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "client-detail" */ "./views/app/client/"
              ),
            redirect: `${adminRoot}/clients/:id/${hasPermission(['view onboarding client']) ? 'onboarding' : 'info'}`,
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client"],
            },
            children: [
              {
                path: "info",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/info"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "info/services",
                component: () =>
                  import(
                    /* webpackChunkName: "client-service" */ "./views/app/client/service/ServiceIndex"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "contacts",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/contact"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "contacts/create",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/contact/ContactDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["create client"],
                },
              },
              {
                path: "contacts/update/:contactId",
                component: () =>
                  import(
                    /* webpackChunkName: "client-contact" */ "./views/app/client/contact/ContactDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["edit client"],
                },
              },
              {
                path: "addresses",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/address"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "addresses/list",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/address/AddressIndex"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "addresses/create",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/address/AddressDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["create client"],
                },
              },
              {
                path: "addresses/update/:addressId",
                component: () =>
                  import(
                    /* webpackChunkName: "client-contact" */ "./views/app/client/address/AddressDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["edit client"],
                },
              },
              {
                path: "relationship-managers",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/rm"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "board-management",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/boardManagement"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "relationship-managers/list",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/rm/RmIndex"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "relationship-managers/create",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/rm/RmDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["create client"],
                },
              },
              {
                path: "relationship-managers/update/:clientRmId",
                component: () =>
                  import(
                    /* webpackChunkName: "client-contact" */ "./views/app/client/rm/RmDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["edit client"],
                },
              },
              {
                path: "invoices",
                component: () =>
                  import(
                            /* webpackChunkName: "client-detail" */ "./views/app/client/bdNexusInvoices"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "bd",
                component: () =>
                  import(
                            /* webpackChunkName: "client-detail" */ "./views/app/client/bdNexus"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "onboarding-status",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/onboarding_status"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "documents",
                name: "client-documents",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/document"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "shareholders",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/shareholder"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "shareholders/list",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/shareholder/ShareholderIndex"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "onboarding",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/onboarding/index"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "shareholders/create-corporate",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/shareholder/CorporateShareholderDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["create client"],
                },
              },
              {
                path: "shareholders/create-individual",
                component: () =>
                  import(
                    /* webpackChunkName: "client-team" */ "./views/app/client/shareholder/IndividualShareholderDetail"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["create client"],
                },
              },
              {
                path: "comment",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/comment"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "corporate-secretary",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/corporate_secretary"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "bank-details",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/bank_details"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "approval-rights",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail" */ "./views/app/client/approval_rights"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
              {
                path: "payroll",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "payroll/employee/create",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll/employee/create"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "payroll/employee/:employeeId",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll/employee/"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "payroll/employee/:employeeId/payslips",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll/employee/payslip/"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "payroll/employee/:employeeId/documents",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll/employee/document/"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "payroll/documents",
                component: () =>
                  import(
                    /* webpackChunkName: "client-detail-payroll" */ "./views/app/client/payroll/employee/document/"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view payroll client"],
                },
              },
              {
                path: "shared-folder",
                component: () =>
                  import(
                    /* webpackChunkName: "client-shared-folder" */ "./views/app/client/shared_folder/"
                  ),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view shared folder client"],
                },
              },
              {
                path: "hubspot/info/:hubspotId",
                component: () => import("./views/app/client/bd/info"),
                meta: {
                  loginRequired: true,
                  isUserPortal: true,
                  permissions: ["view client"],
                },
              },
            ],
          },
        ],
        //     },
        //   ]
      },

      {
        path: "parent-groups",
        component: () =>
          import(
            /* webpackChunkName: "client-team" */ "./views/app/parent_group"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
          permissions: ["view ubo"],
        },
        redirect: `${adminRoot}/parent-groups/list`,
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "client-team" */ "./views/app/parent_group/ParentGroupIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view ubo"],
            },
          },
          {
            path: "edit-corporate/:id",
            component: () =>
              import(
                /* webpackChunkName: "client-team" */ "./views/app/parent_group/CorporateParentGroupDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["edit ubo"],
            },
          },
          {
            path: "create-corporate",
            component: () =>
              import(
                /* webpackChunkName: "client-team" */ "./views/app/parent_group/CorporateParentGroupDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create ubo"],
            },
          },
          {
            path: "edit-individual/:id",
            component: () =>
              import(
                /* webpackChunkName: "client-team" */ "./views/app/parent_group/IndividualParentGroupDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create ubo"],
            },
          },
          {
            path: "create-individual",
            component: () =>
              import(
                /* webpackChunkName: "client-team" */ "./views/app/parent_group/IndividualParentGroupDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create ubo"],
            },
          },
        ],
      },
      {
        path: "onboarding-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "employee-onboarding-dashboard" */
            "./views/app/onboarding-dashboard"
          ),
        redirect: `${adminRoot}/onboarding-dashboard/list/my-clients`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [{
          path: "list",
          redirect: `${adminRoot}/onboarding-dashboard/list/my-clients`,
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/OnboardingDashboardIndex"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "list/:type",
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/OnboardingDashboardIndex"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "ready-initiation",
          redirect: `${adminRoot}/onboarding-dashboard/ready-initiation/my-clients`,
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/ReadyInitiation"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "ready-initiation/:type",
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/ReadyInitiation"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "waiting-onboarding",
          redirect: `${adminRoot}/onboarding-dashboard/waiting-onboarding/my-clients`,
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/WaitingOnboarding"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "waiting-onboarding/:type",
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/WaitingOnboarding"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "waiting-review",
          redirect: `${adminRoot}/onboarding-dashboard/waiting-review/my-clients`,
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/WaitingReview"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "waiting-review/:type",
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/WaitingReview"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "latest-onboarded",
          redirect: `${adminRoot}/onboarding-dashboard/latest-onboarded/my-clients`,
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/LatestOnboarded"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        },
        {
          path: "latest-onboarded/:type",
          component: () =>
            import(
              /* webpackChunkName: "onboarding-dashboard" */
              "./views/app/onboarding-dashboard/LatestOnboarded"
            ),
          meta: {
            loginRequired: true,
            isUserPortal: true,
            permissions: ["view onboarding_dashboard"],
          },
        }],
      },
      {
        path: "acclime-sign",
        component: () =>
          import(
            /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign"
          ),
        redirect: `${adminRoot}/acclime-sign/list`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/AcclimeSignIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "document-to-sign",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/AcclimeSignDocumentToSign"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "document-to-sign/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/SignDocument"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "document-to-sign/confirmation/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/SignedConfirmation"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view acclime sign"],
            },
          },
          {
            path: "create/select-document",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/SelectDocument"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/add-signers/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/AddSigner"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/add-observers/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/AddObserver"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/configure-document/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/ConfigureDocument"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/sign-document/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/DirectlySignDocument"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/sign-document-done/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/DirectlySignDocumentDone"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/send-for-signing/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/SendForSigning"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
          {
            path: "create/confirmation/:id",
            component: () =>
              import(
                /* webpackChunkName: "employee-acclime-sign" */ "./views/app/acclimesign/Confirmation"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["create acclime sign"],
            },
          },
        ],
      },
      {
        path: "conflict-search",
        component: () =>
          import(
            /* webpackChunkName: "employee-conflict-search" */ "./views/app/conflict-search"
          ),
        redirect: `${adminRoot}/conflict-search/list`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "conflict-search" */ "./views/app/conflict-search/ConflictSearchIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view conflict search"],
            },
          },
        ],
      },
      {
        path: "analytics",
        component: () =>
          import(/* webpackChunkName: "analytic" */ "./views/app/analytic"),
        redirect: `${adminRoot}/analytics/client-analytics`,
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
        children: [
          {
            path: "client-analytics",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/analytic/ClientAnalytic"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view client analytics"],
            },
          },
          {
            path: "user-analytics",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/analytic/UserAnalytic"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view user analytics"],
            },
          },
          {
            path: "data-quality-analytics",
            component: () =>
              import(
                /* webpackChunkName: "analytic" */ "./views/app/analytic/DataQualityAnalytic"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view data quality analytics"],
            },
          },
        ],
      },
      {
        path: "client-report",
        component: () =>
          import(
            /* webpackChunkName: "activity-log" */ "./views/app/clientreport/ClientReportIndex"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
          permissions: ["view client report"],
        },
      },
      {
        path: "log",
        component: () =>
          import(
            /* webpackChunkName: "activity-log" */ "./views/app/activitylog/ActivitylogIndex"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
          permissions: ["view activity log"],
        },
      },
      {
        path: "invoices",
        component: () =>
          import(
            /* webpackChunkName: "invoice" */ "./views/app/invoice/InvoiceIndex"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
          permissions: ["view invoice"],
        },
      },
      {
        path: "invoice/stat",
        component: () =>
          import(
            /* webpackChunkName: "invoice" */ "./views/app/invoice/InvoiceStatIndex"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
          permissions: ["view invoice"],
        },
      },
      {
        path: "ad",
        component: () =>
          import(/* webpackChunkName: "shareholder" */ "./views/app/ad/"),
        redirect: `${adminRoot}/ad/list`,
        meta: {
          loginRequired: true,
        },
        children: [
          {
            path: "list",
            component: () =>
              import(
                /* webpackChunkName: "shareholder" */ "./views/app/ad/AdIndex"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["view ad group"],
            },
          },
          {
            path: ":id/map",
            component: () =>
              import(
                /* webpackChunkName: "shareholder" */ "./views/app/ad/AdDetail"
              ),
            meta: {
              loginRequired: true,
              isUserPortal: true,
              permissions: ["edit ad group"],
            },
          },
        ],
      },
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "./views/app/profile/ProfileIndex"
          ),
        name: "profile",
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
      },
      {
        path: "release-notes",
        component: () =>
          import(
            /* webpackChunkName: "release-note" */ "./views/app/releasenote/ReleaseNoteIndex"
          ),
        meta: {
          loginRequired: true,
          isUserPortal: true,
        },
      },
    ],
  },
  {
    path: "/error",
    meta: { loginRequired: false },
    component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
  },
  {
    path: "/unauthorized",
    meta: { loginRequired: false },
    component: () =>
      import(/* webpackChunkName: "error" */ "./views/Unauthorized"),
  },
  {
    path: "/redirect-action",
    meta: { loginRequired: false },
    component: () =>
      import(/* webpackChunkName: "error" */ "./views/RedirectAction"),
  },
  {
    path: "*",
    meta: { loginRequired: false },
    component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
  },
]

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});
router.beforeEach(AuthGuard);
export default router;
